import firebase from "../../db/firebase/MFirebase";
import { Type } from "../../types/Types";

const initState = {
  searchString: null,
  type: null,
  filters: {},
  items: [],
};

export default {
  name: "search",
  namespaced: true,

  state: {
    ...initState,
  },

  mutations: {
    setType(state, type) {
      state.type = type;
      state.filters = {};
      if (type) {
        type.searchKeys.forEach((key) => {
          state.filters[key] = {
            active: false,
            text: "",
          };
        });
      }
    },

    setSearchString(state, searchString) {
      // Added new mutation to set the searchString
      state.searchString = searchString;
    },

    setItems(state, items) {
      // Added new mutation to set the "items" array
      state.items = items;
    },

    clearAll(state) {
      Object.assign(state, initState);
    },

    setFilterActive(state, { key, active }) {
      state.filters[key].active = active;
    },
  },

  actions: {
    setType({ commit, state }, type) {
      if (type && !(type instanceof Type)) type = new Type(type);
      commit("setType", type);

      // Clear the "items" array when the type is set
      commit("setItems", []);

      // Clear the search string when the type is set
      commit("setSearchString", "");

      // Call searchItems after setting the type
      searchItems({ commit, state });
    },

    setSearchString({ commit, state }, searchString) {
      commit("setSearchString", searchString);
      searchItems({ commit, state });
    },

    setFilterActive({ commit, state }, { key, active }) {
      commit("setFilterActive", { key, active });
      searchItems({ commit, state });
    },

    clearSearch({ commit }) {
      commit("clearAll");
    },
  },

  getters: {
    getType: (state) => state.type,
    getFilters: (state) => state.filters,
    getFiltersActive: (state) => {
      const activeFilters = Object.keys(state.filters).filter(
        (key) => state.filters[key].active
      );
      return activeFilters;
    },
    getFilterKeys: (state) => Object.keys(state.filters),
    getItems: (state) => state.items, // Getter for the "items" array
  },
};

async function searchItems({ commit, state }) {
  const { type, searchString, filtersActive } = state;
  if (type && searchString) {
    const items = await firebase.getItemsBy(
      type,
      searchString,
      filtersActive,
      null,
      5,
      false
    );
    commit("setItems", items);
  }
}
