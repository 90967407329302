// ro.js

const ro = {
  welcome: "Bun venit",
  login: "Autentificare",
  go: "Continuă",
  short: "Kürzel",
  edit: "Editează",
  newAccount: "Cont nou",
  basics: "Generell",
  emptyEntry: "Keine Angaben",
  loading: "Se încarcă",
  category: "Categorie",
  color: "Culoare",
  recoms: "Empfehlung",
  due: "Fällig",
  ok: "Ok",
  general: "Generell",
  title: "Titlu",
  text: "Text",
  desc: "Descriere",
  created: "Creat",
  updated: "Actualizat ultima dată",
  saving: "Se salvează",
  takeAndGo: "Preia și continuă",
  website: "Webseite",
  extras: "Extras",
  NA: "Keine Angaben",
  options: "Opțiuni",
  name: "Nume",
  addcompany: "Adaugă companie",
  selectCompanyToStart: "Selectează o companie pentru a începe",
  enterToCreate: "'Enter' pentru a crea.",
  onlySearch: "Doar pentru căutare",
  new: "Nou",
  more: "Mai mult",
  less: "Mai puțin",
  create: "Creează",
  select: "Selectează",
  cancel: "Anulează",
  close: "Închide",
  add: "Adaugă",
  save: "Salvează",
  delete: "Șterge",
  deleteSure: "Sigur dorești să ștergi",
  deleteEntry: "Șterge înregistrare",
  email: {
    base: "E-Mail",
    enter: "Introdu adresa de email",
  },
  phone: {
    base: "Telefon",
    enter: "Introdu numărul de telefon",
  },
  password: {
    base: "Parolă",
    enter: "Introdu parola",
  },
  list: {
    empty: "Nicio înregistrare",
  },
  last: {
    title: "Ultima",
    updated: "Ultima actualizare",
  },
  countsHave: "{count} disponibile",
  request: {
    title: "Cerere",
    create: "Crează cerere",
  },
  project: {
    title: "Proiect",
    titles: "Proiecte",
    infos: "Informații despre proiect",
    name: "Numele proiectului",
    none: "Niciun proiect",
  },
  customer: {
    title: "Client",
    titles: "Clienți",
    short: "Nume scurt",
    noSelected: "Niciun client selectat",
    none: "Niciun client",
  },
  supplier: {
    title: "Furnizor",
    titles: "Furnizori",
    short: "Nume scurt",
    noSelected: "Niciun furnizor selectat",
    none: "Niciun furnizor",
  },
  basedata: {
    title: "Articol",
    titles: "Articole",
    add: "Adaugă articol",
    noSelected: "Niciun articol selectat",
    sku: "Număr articol",
    tracking: "Tracking",
    sizes: "Dimensiuni",
    quality: "Calitate?",
  },
  contact: {
    title: "Contact",
    titles: "Contacte",
    type: "Tip de contact",
    short: "Kürzel",
    add: "Adaugă contact",
    noSelected: "Niciun contact selectat",
    m: "Domn",
    w: "Doamnă",
  },
  user: {
    title: "Utilizator",
    titles: "Utilizatori",
    invite: "Invită utilizator",
    short: "Kürzel",
    prename: "Prenume",
    surname: "Nume",
    noSelected: "Niciun utilizator selectat",
  },
  location: {
    title: "Locație",
    titles: "Locații",
    short: "Kürzel",
    add: "Adaugă locație",
    street: "Stradă",
    nr: "Număr",
    zip: "Cod poștal",
    city: "Oraș",
    noSelected: "Nicio locație selectată",
  },
  tag: {
    title: "Etichetă",
    titles: "Etichete",
    add: "Adaugă etichetă",
  },
  note: {
    title: "Notiță",
    titles: "Notițe",
  },
  todo: {
    title: "Sarcină",
    titles: "Sarcini",
  },
  event: {
    title: "Eveniment",
    titles: "Evenimente",
  },
  doc: {
    title: "Document",
    titles: "Documente",
  },
  manu: {
    base: "Producător",
    sku: "H-Articol număr",
    web: "H-Pagina web",
    desc: "H-Descriere",
  },
  tracking: {
    base: "Tracking",
    none: "Niciun tracking",
    SN: "Număr de serie",
    CH: "Cod lot",
    CHPre: "Prefix lot",
  },
  sizes: {
    base: "Dimensiuni",
    l: "Lungime",
    b: "Lățime",
    h: "Înălțime",
    weight: "Greutate",
    var: {
      m: "Metri",
      cm: "Centimetri",
      mm: "Milimetri",
      kg: "Kilograme",
      g: "Grame",
      mg: "Miligrame",
    },
  },
  company: {
    title: "Companie",
    multi: "Companii",
    add: "Adaugă companie",
    new: "Companie nouă",
    edit: "Editează companie",
  },
  error: {
    email: {
      invalid: "Adresă de email invalidă.",
    },
    userDisabled: "Contul utilizatorului este dezactivat.",
    userNotFound: "Utilizatorul nu a fost găsit.",
    incorrectPassword: "Parolă incorectă.",
    passwordRequired: "Parola este obligatorie.",
    generic: "A apărut o eroare. Vă rugăm să încercați mai târziu.",
  },
};

export default ro;
