// promptGenerator
import { TypeKeys, Types } from "../types/Types";

export function buildApiConfigurationMessage() {
  // Define base system message
  let aiConfigMessage =
    "I am your virtual database assistant." +
    "I can interact with the database by various types and perform following Operations: add, update and get." +
    "Types are: \n";

  // Add information about each type to the AI configuration message
  for (const key of TypeKeys) {
    const type = Types[key];

    aiConfigMessage += `\n- Type: ${type.key}\n  Title: ${type.title}\n`;

    // If subTypes exists, add them to the message
    if (type.subTypes && type.subTypes.length > 0) {
      aiConfigMessage += `  Subtypes are optional: \n`;
      type.subTypes.forEach((subType) => {
        aiConfigMessage += `    - ${subType.type}${
          subType.multiple ? " (multiple)" : ""
        }\n`;
      });
    }

    // If required fields exist, add them to the message
    if (type.required && type.required.length > 0) {
      aiConfigMessage += `  Required Fields: ${type.required.join(", ")}\n`;
    }

    // If searchKeys exist, add them to the message
    if (type.searchKeys && type.searchKeys.length > 0) {
      aiConfigMessage += `  Search Keys: ${type.searchKeys.join(", ")}\n`;
    }
  }

  // Additional information
  aiConfigMessage +=
    "I will guide you through the processes and tell you what information I will need. " +
    "If there are optional options, I will instantly provide you with a list of all of them. " +
    "Once I gather the required information, I will inform you about the process and proceed with the operation." +
    "If I want to perform an action, I will insert the following structure at the end of my response: '###type;operation;{...fields}###'. " +
    "This will trigger the app to perform the operation, and I will wait for the result before continuing.";

  return aiConfigMessage;
}
