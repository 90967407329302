<template>
  <base-dialog
    dialKey="company"
    :title="$t('company.title')"
    icon="$company"
    :loading="loading"
  >
    <template #text>
      <div class="vertical gapped" style="padding: 2rem 0 0 0">
        <v-text-field
          name="name"
          label="Name"
          id="name"
          background-color="#fefefe33"
          v-model="company.title"
          solo
          flat
          rounded
          hide-details
        ></v-text-field>

        <v-expand-transition>
          <div class="vertical gapped" v-show="showMore">
            <h4 style="margin-top: 1rem">Optional</h4>
            <div
              class="mi-connected horizontal m-bg-white gapped half"
              style="padding: 0.5rem 1rem"
            >
              <v-icon>mdi-phone</v-icon>
              <v-text-field
                name="phone"
                :label="$t('phone.base')"
                id="phone"
                background-color="transparent"
                flat
                dense
                hide-details
                solo
                v-model="company.contact.phone"
              ></v-text-field>
            </div>
            <div
              class="mi-connected horizontal m-bg-white gapped half"
              style="padding: 0.5rem 1rem"
            >
              <v-icon>mdi-email</v-icon>
              <v-text-field
                name="mail"
                :label="$t('email.base')"
                id="mail"
                background-color="transparent"
                flat
                dense
                hide-details
                solo
                v-model="company.contact.mail"
              ></v-text-field>
            </div>
          </div>
        </v-expand-transition>

        <v-btn
          color="action"
          :disabled="loading"
          text
          @click="showMore = !showMore"
        >
          <v-icon
            :style="{ transform: showMore ? 'rotate(180deg)' : 'rotate(0)' }"
            >mdi-chevron-down</v-icon
          >
          {{ !showMore ? $t("more") : $t("less") }}
        </v-btn>
      </div>
    </template>

    <template #actions>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="actions" depressed rounded @click="create">{{
          $t("create")
        }}</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </template>
  </base-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import BaseDialog from "../../../views/dialogs/BaseDialog.vue";
import Company from "../company";
export default {
  components: { BaseDialog },
  name: "CompanyDialog",

  data() {
    return {
      company: new Company(),
      showMore: false,
      loading: false,
    };
  },

  computed: {
    ...mapGetters("miuser", ["getUser"]),
    ...mapGetters("dialog", ["getDialogData"]),

    dialData() {
      return this.getDialogData("company");
    },
  },

  mounted() {
    if (this.dialData) this.company = this.dialData;
  },

  methods: {
    ...mapActions("dialog", ["hideDialog"]),
    ...mapActions("company", ["saveCompany"]),
    ...mapActions("miuser", ["addCompanyToUser"]),
    ...mapActions("worker", ["saveWorker"]),

    async create() {
      this.loading = true;
      const id = this.getUser.id;
      this.company.users = [id];
      this.company.admins = [id];
      this.mCompany = await this.saveCompany({
        user: this.getUser,
        company: this.company,
      });
      this.addCompanyToUser(this.mCompany);
      this.saveWorker(this.getUser);
      this.isNewAccount = false;
      this.loading = false;
      this.hideDialog("company");
    },
  },
};
</script>

<style lang="scss" scoped></style>
