<template>
  <base-dialog dialKey="location" :title="$t('location.title')">
    <template #text="{ item }">
      <location-edit-fields
        v-if="item"
        :location="item"
        hide-title
        style="margin: 2rem 0"
        @changed="item[$event.key] = $event.value"
      />
    </template>
  </base-dialog>
</template>

<script>
import BaseDialog from "../../../views/dialogs/BaseDialog.vue";
import LocationEditFields from "../components/LocationEditFields.vue";
export default {
  components: { BaseDialog, LocationEditFields },
  name: "MisolarLocationDialog",

  data() {
    return {};
  },
  computed: {},

  methods: {},
};
</script>

<style lang="scss" scoped></style>
