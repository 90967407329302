import Struct from "./datas/contact.json";

export default class Contact {
  constructor(contact) {
    if (Struct) {
      Object.assign(this, Struct);
    }
    if (contact) {
      // Object.keys(this).forEach((it) => {
      //   if (contact[it]) this[it] = contact[it];
      // });
      Object.assign(this, contact);
    }
  }

  name() {
    return this.prename + " " + this.surname;
  }

  anrede() {
    if (!this.sex) return undefined;
    return this.sex == "m" ? "Herr" : "Frau";
  }

  icon() {
    return "$contact" + this.sex || "";
  }

  clear() {
    for (let key of Object.keys(this)) {
      this[key] = "";
    }
  }

  toSaveable() {
    let item = { ...this };
    return item;
  }

  complete() {
    return this.title || this.prename || this.surname;
  }
}
