import Struct from "./datas/location.json";

export function linkToMaps(location) {
  return (
    "https://maps.google.com/?q=" +
    location.street +
    " " +
    +location.nr +
    ", " +
    location.zip +
    " " +
    location.city
  );
}

export default class Location {
  constructor(location) {
    if (Struct) {
      Object.assign(this, Struct);
    }
    if (location) Object.assign(this, location);
  }

  clear() {
    for (let key of Object.keys(this)) {
      this[key] = "";
    }
  }

  toSaveable() {
    let item = { ...this };
    return item;
  }

  complete() {
    return this.title && this.street;
  }
}
