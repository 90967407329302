import { cloneDeep } from "lodash";
import { miLog } from "../../main";
import { Type } from "../Types";
import Struct from "./data/miForm.json";

// TODO: outsource
export const formsConfig = [
  {
    title: "Objektdaten",
    icon: "mdi-home",
    entries: [
      {
        title: "Objektart",
        type: "String",
        value: null,
      },
      {
        title: "Nutzfläche",
        type: "String",
        value: null,
      },
      {
        title: "Ölverbrauch",
        type: "String",
        value: null,
      },
      {
        title: "Gasverbrauch",
        type: "Number",
        value: null,
      },
      {
        title: "Gebäudeheizlast",
        type: "String",
        value: null,
      },
    ],
  },
  {
    title: "Leistungsbeschreibung",
    icon: "mdi-card-bulleted",
    entries: [
      {
        title: "Heizung",
        type: "Checkbox",
        multiple: true,
        entries: [
          "Naturkühlung",
          "Umkehrlüftung",
          "Solaranlage",
          "Schwimmbad",
          "Trink-Wasseraufbereitung",
        ],
        value: null,
      },
      {
        title: "Heizungsanlage",
        type: "Checkbox",
        entries: [
          "Fußbodenheizung ohne ERR",
          "Fußbodenheizung mit ERR",
          "Radiatoren",
          "RLT-Anlage",
          "Betonkernaltivierung",
          "Gebläsekonvektoren",
        ],
        value: null,
      },
      {
        title: "Kühlung",
        type: "Checkbox",
        entries: [
          "Fußbodenheizung",
          "Betonkernaltivierung",
          "Gebläsekonvektoren",
          "RLT-Anlage",
          "kW Kühlleistung",
          "°C Vorlauftemperatur",
        ],
        value: null,
      },
      {
        title: "Trink-Warmwasserbereitung",
        type: "Checkbox",
        entries: [
          "zentral",
          "dezentral",
          "12 Anzahl Personen",
          "kWh/A Warmwasserbedarf",
        ],
        value: null,
      },
      {
        title: "Voraussichtliche Wärmequelle",
        type: "Checkbox",
        entries: [
          "Außenluft",
          "Grundwasser",
          "Erdsonde",
          "Fortluft",
          "Flächenkollektoren",
          "Gründungspfähle",
          "Prozesswasser",
          "°C",
        ],
        value: null,
      },
    ],
  },
];

export default class MiForm {
  constructor(formular) {
    if (Struct) {
      Object.assign(this, Struct);
    }
    if (formular) {
      Object.assign(this, formular);
      if (formular instanceof MiForm) return;
    }
    this.type = new Type("form");

    miLog("Created new Form", formular);
  }

  toSaveable() {
    let item = { ...cloneDeep(this) };
    delete item.type;
    return item;
  }
}
