<template>
  <v-navigation-drawer
    app
    :width="smallState ? 320 : 200"
    class="sidebar elevation-0"
    :value="showSide"
    :permanent="showSide"
    floating
    color="primary darken-1"
  >
    <div class="vertical cont centered-h">
      <div class="first-cont horizontal gapped centered">
        <span style="width: 50%">
          MiSolar
          {{ getCurrentTimeFormatted }}
        </span>
      </div>

      <div class="vertical gapped half">
        <v-expand-transition>
          <v-hover v-slot="{ hover }">
            <v-card
              class="vertical"
              v-if="currentOrNextEvent"
              :class="
                currentOrNextEvent.start < currentTime ? ' event-started ' : ''
              "
              style="
                margin: 0.5rem;
                margin-bottom: 0;
                padding: 8px 16px;
                border-radius: 1rem;
              "
              @click="showDialog({ key: 'event', data: currentOrNextEvent })"
            >
              <div class="horizontal centered-v" style="font-size: 1rem">
                <v-icon small color="primary" style="margin-right: 4px">
                  $events
                </v-icon>
                {{ currentOrNextEvent.name }}
              </div>
              <v-expand-transition>
                <div
                  class="vertical"
                  style="font-size: 0.8rem; color: grey"
                  v-if="hover"
                >
                  <div class="horizontal centered-v">
                    <v-icon color="primary" small style="margin-right: 4px">
                      mdi-clock-start
                    </v-icon>
                    <strong>
                      {{ new Date(currentOrNextEvent.start).toLocaleString() }}
                    </strong>
                  </div>

                  <div class="horizontal centered-v">
                    <v-icon color="primary" small style="margin-right: 4px">
                      mdi-clock-end
                    </v-icon>
                    <strong>{{
                      new Date(currentOrNextEvent.end).toLocaleString()
                    }}</strong>
                  </div>
                  <div
                    class="horizontal gapped half wrapped"
                    style="margin-top: 4px"
                  >
                    <v-chip
                      x-small
                      outlined
                      v-for="user in currentOrNextEvent.users"
                      :key="user.title"
                    >
                      {{ user.title }}
                    </v-chip>
                  </div>
                </div>
              </v-expand-transition>
            </v-card>
          </v-hover>
        </v-expand-transition>
      </div>

      <div class="mi-connected horizontal centered">
        <!-- style="border-top: 1px dashed white" -->
        <hover-button
          class="m-bg-white"
          title="Zuletzt"
          icon="mdi-history"
          :color="mode == 1 ? 'nav' : '#fefefe33'"
          @click="setMode(1)"
          :open-delay="2000"
          small
        >
        </hover-button>

        <hover-button
          class="m-bg-white"
          title="Gepinnt"
          :icon="mode == 2 ? 'mdi-pin' : 'mdi-pin-outline'"
          :color="mode == 2 ? 'nav' : '#fefefe33'"
          @click="setMode(2)"
          :open-delay="2000"
          small
        >
        </hover-button>

        <hover-button
          class="m-bg-white"
          title="Gepinnt"
          :icon="mode == 3 ? 'mdi-bookmark' : 'mdi-bookmark-outline'"
          :color="mode == 3 ? 'nav' : '#fefefe33'"
          @click="setMode(3)"
          :open-delay="2000"
          small
        >
        </hover-button>
      </div>

      <div class="mi-connected horizontal centered" style="margin-top: 0.5rem">
        <con-button
          class="m-bg-white"
          v-for="(mtype, i) in listTypes"
          :key="'filter' + i + mtype.key"
          :icon="mtype.icon"
          :color="isSelected(mtype) ? 'accent' : '#fefefe33'"
          @click="toggleSelected(mtype)"
          x-small
        >
        </con-button>
        <con-button
          v-show="selectedType"
          icon="mdi-close"
          @click="setSelected()"
          x-small
        />
        <div></div>
      </div>

      <div class="current-cont vertical gapped" style="padding: 0.5rem">
        <!-- v-if="mode != -1" -->
        <side-bar-list
          :currentSideList="currentSideList"
          :isCurrent="isCurrent"
          :goTo="goTo"
        />
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
import DocTypes from "@/types/doc/datas/doctypes.json";
import { mapActions, mapGetters, mapState } from "vuex";
import HoverButton from "../../../components/connected/HoverButton.vue";
import ConButton from "../../../components/connected/ConButton.vue";
import SideBarList from "./SideBarList.vue";
import { currentCompanyID } from "../../../../db/firebase/MFirebase";

export default {
  components: {
    HoverButton,
    ConButton,
    SideBarList,
  },

  props: {
    hide: Boolean,
  },

  data() {
    return {
      selectedType: undefined,
      drag: false,
      mode: 1,

      listeners: {
        events: null,
      },
    };
  },

  async created() {},

  mounted() {
    if (this.smallState) {
      this.setHideLeft(true);
    }
  },

  computed: {
    ...mapState(["currentTime"]),
    ...mapGetters(["smallState"]),
    ...mapGetters("favorites", [
      "getRecentItems",
      "getPinnedItems",
      "getBookmarks",
      "currentItem",
    ]),

    ...mapGetters([
      "getCurrentTimeFormatted",
      "nextEvent",
      "nextEvents",
      "currentEvent",
    ]),
    ...mapGetters("appbar", ["getHideLeft"]),

    listTypes() {
      const uniqueTypes = [];
      const typeKeys = new Set();

      for (const item of this.currentSideList) {
        const typeKey = item.type.key;
        if (!typeKeys.has(typeKey)) {
          typeKeys.add(typeKey);
          uniqueTypes.push({ ...item.type });
        }
      }
      return uniqueTypes;
    },

    currentSideList() {
      switch (this.mode) {
        case 1:
          return this.filterByType(this.getRecentItems);
        case 2:
          return this.filterByType(this.getPinnedItems);
        case 3:
          return this.filterByType(this.getBookmarks);
        default:
          return [];
      }
    },

    showSide: {
      get() {
        return !this.getHideLeft;
      },
      set(value) {
        this.setHideLeft(value);
      },
    },

    currentOrNextEvent() {
      if (this.currentEvent) {
        return this.currentEvent;
      } else if (this.nextEvents.length > 0) {
        return this.nextEvents[0];
      } else {
        return null; // No current or upcoming events
      }
    },
  },

  methods: {
    ...mapActions("appbar", ["showDialog", "setHideLeft"]),

    setMode(mode) {
      this.mode = mode;
    },

    filterByType(items) {
      // If no type is selected, return all items
      if (!this.selectedType) {
        return items;
      }

      // Filter items by the selected type
      return items.filter((item) => item.type.key === this.selectedType.key);
    },

    goTo(item) {
      if (item.type.router.to) {
        this.$router.push(item.type.router.to(item));
        return;
      }
      this.$router.push({
        path: "/c/" + currentCompanyID + item.type.router.path + item.id,
      });
    },

    isSelected(type) {
      return this.selectedType?.key == type.key;
    },

    setSelected(type) {
      this.selectedType = type;
    },

    toggleSelected(type) {
      if (this.selectedType?.key == type.key) {
        this.selectedType = undefined;
        return;
      }
      this.selectedType = type;
    },

    isCurrent(item) {
      if (!this.currentItem) return false;
      try {
        return (
          item &&
          this.currentItem &&
          item.id == this.currentItem.id &&
          item.type.key == this.currentItem.type.key
        );
      } catch (error) {
        return false;
      }
    },
    isProject(item) {
      return (
        item && item.type && (item.type.key === "project" || item.type === 1)
      );
    },
    isCustomer(item) {
      return (
        item && item.type && (item.type.key === "customer" || item.type === 8)
      );
    },
    isSupplier(item) {
      return (
        item && item.type && (item.type.key === "supplier" || item.type === 12)
      );
    },
    isMaterial(item) {
      return (
        item && item.type && (item.type.key === "basedata" || item.type === 10)
      );
    },
    isWorker(item) {
      return item && item.type && (item.type.key === "user" || item.type === 9);
    },
    isDoc(item) {
      return item && DocTypes[item.type];
    },

    beforeLeave(el) {
      const { marginLeft, marginTop, width, height } =
        window.getComputedStyle(el);
      el.style.left = `${el.offsetLeft - parseFloat(marginLeft, 10)}px`;
      el.style.top = `${el.offsetTop - parseFloat(marginTop, 10)}px`;
      el.style.width = width;
      el.style.height = height;
    },
  },

  watch: {
    smallState(value) {
      if (value) this.setHideLeft(true);
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar {
  color: white;
  overflow: hidden;
  transition: 0.5s all cubic-bezier(0.075, 0.82, 0.165, 1);

  .cont {
    height: 100%;
  }

  &.hide {
    min-width: 1px !important;
    width: 1px !important;
    max-width: 1px !important;
    overflow: hidden;
  }

  .first-cont {
    width: 100%;
    padding-left: 2rem;
    height: 64px !important;
    min-height: 64px !important;
  }

  .mode-cont {
    width: 95%;
    padding: 0.5rem;
    margin: 0 auto;
    border: 1px solid gray;
    border-radius: 2rem;
  }

  .current-cont {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    // border-bottom: 1px solid gray;
  }

  .content-cont {
    border-top: 1px solid white;
  }

  .new-cont {
    border: 1px dashed white;
    margin: 0 8px 4px 8px;
    padding: 0.5rem;
    border-radius: 1rem;
  }

  .event-started {
    border: 2px solid lightgreen !important;
  }
}

// From example
.draggables {
  height: 100%;
  padding-top: 0.5rem;
}
</style>
