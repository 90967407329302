import { cloneDeep } from "lodash";
import firebase from "../../db/firebase/MFirebase";
import Contact from "../contact/contact";
import Location from "../location/location";
import { Type } from "../Types";
import Struct from "./datas/customer.json";

export default class Customer {
  constructor(customer) {
    if (Struct) {
      Object.assign(this, Struct);
    }
    if (customer) {
      Object.assign(this, customer);
      if (customer instanceof Customer) return;

      if (!customer.contacts) customer.contacts = [];
      this.contacts = customer.contacts.map((it) => {
        return new Contact(it);
      });
      if (!customer.locations) customer.locations = [];
      this.locations = customer.locations.map((it) => {
        return new Location(it);
      });
    }
    this.type = new Type("customer");
  }

  // DB
  toSaveable() {
    let item = { ...cloneDeep(this) };
    item.locations = item.locations.map((it) => {
      if (it.toSaveable) return it.toSaveable();
      return it;
    });
    item.contacts = item.contacts.map((it) => {
      if (it.toSaveable) return it.toSaveable();
      return it;
    });
    delete item.type;
    delete item.projects;
    return item;
  }

  async add(onAdd) {
    let item = { ...this.toSaveable() };
    item = await firebase.addItem(this.type, item, onAdd);
    this.id = item.id;
    this.created = item.created;
    this.updated = item.updated;
    return this;
  }

  async save() {
    let item = { ...this.toSaveable() };
    item = await firebase.setItem(this.type.key, item);
    this.created = item.created;
    this.updated = item.updated;
    return this;
  }
  delete() {}
}
