// Types
import { Todo, TodoGroup } from "./todo/Todos";
import Basedata from "./basedata/basedata";
import Customer from "./customer/customer";
import Doc from "./doc/doc";
import MiEvent from "./event/mievent";
import Goal from "./goal/goal";
import Note from "./note/note";
import Project from "./project/project";
import Supplier from "./supplier/supplier";
import MiUser from "./user/MiUser";
import icons from "../plugins/icons";
import MiForm from "./form/form";

export default {
  version: 0.9,
  name: "Types",

  entries: {
    project: {
      key: "project",
      title: "Projekt",
      titles: "Projekte",
      class: Project,
      router: {
        path: "/project/",
        name: "ProjectPage",
      },
      db: "projects",
      icon: icons.project,
      searchKeys: ["id", "title"],
      searchBools: ["finished"],
      required: ["title"],
      subTypes: [
        { type: "customer" },
        { type: "location", multiple: true },
        { type: "contact", multiple: true },
      ],
      isAddable: true,
      isFav: true,
    },

    customer: {
      key: "customer",
      title: "Kunde",
      titles: "Kunden",
      class: Customer,
      router: {
        path: "/customer/",
        name: "Customer",
      },
      db: "customers",
      icon: icons.customer,
      searchKeys: ["id", "title", "name"],
      isAddable: true,
      isFav: true,
    },

    supplier: {
      key: "supplier",
      title: "Lieferant",
      titles: "Lieferanten",
      class: Supplier,
      router: {
        path: "/supplier/",
        name: "Supplier",
      },
      db: "suppliers",
      icon: icons.supplier,
      searchKeys: ["id", "title", "name"],
      isAddable: true,
      isFav: false,
    },

    basedata: {
      key: "basedata",
      title: "Artikel",
      titles: "Artikel",
      class: Basedata,
      router: {
        path: "/basedata/",
        name: "Basedata",
      },
      db: "basedatas",
      icon: icons.basedata,
      searchKeys: ["id", "title", "name"],
      isAddable: true,
      isFav: true,
    },

    user: {
      key: "user",
      title: "User",
      titles: "Users",
      class: MiUser,
      router: {
        path: "/user/",
        name: "User",
      },
      db: "users",
      icon: icons.user,
      searchKeys: ["id", "title", "prename", "surname"],
      isAddable: false,
      isFav: false,
    },

    note: {
      key: "note",
      class: Note,
      title: "Notiz",
      titles: "Notizen",
      db: "notes",
      router: {
        path: "/note/",
        name: "Note",
      },
      icon: icons.note,
      searchKeys: ["id", "title", "text"],
      isAddable: true,
      isFav: true,
    },

    todo: {
      key: "todo",
      title: "Todo",
      titles: "Todos",
      class: Todo,
      db: "todos",
      router: {
        path: "/todo/",
        name: "Todo",
      },
      icon: icons.todo,
      searchKeys: ["id", "title", "users"],
      isAddable: true,
      isFav: true,
    },

    todoGroup: {
      key: "todoGroup",
      class: TodoGroup,
      title: "Gruppe",
      titles: "Gruppen",
      db: "todoGroups",
      router: {
        path: "/todoGroup/",
        name: "TodoGroup",
      },
      icon: icons.todos,
      searchKeys: ["id", "title"],
      isAddable: false,
      isFav: false,
    },

    goal: {
      key: "goal",
      class: Goal,
      title: "Ziel",
      titles: "Ziele",
      db: "goals",
      router: {
        path: "/goal/",
        name: "Goal",
      },
      icon: icons.goal,
      searchKeys: ["id", "title", "desc"],
      isAddable: false,
      isFav: true,
    },

    event: {
      key: "event",
      class: MiEvent,
      db: "events",
      router: {
        path: "/event/",
        name: "MiEvent",
      },
      title: "Termin",
      titles: "Termine",
      icon: icons.event,
      searchKeys: ["id", "title"],
      isAddable: false, //debug
      isFav: false,
    },

    doc: {
      key: "doc",
      class: Doc,
      db: "docs",
      title: "Beleg",
      titles: "Belege",
      router: {
        path: "/doc/",
        name: "DocPage",
      },
      icon: icons.docs,
      searchKeys: ["id", "title"],
      isAddable: false, //debug
      isFav: true,
    },

    form: {
      key: "form",
      class: MiForm,
      db: "forms",
      title: "Formular",
      titles: "Formulare",
      router: {
        path: "/form/",
        name: "FormPage",
      },
      icon: icons.forms,
      searchKeys: ["id", "title"],
      isAddable: false, //debug
      isFav: false,
    },
  },
};
