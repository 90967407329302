import { miError, miLog } from "../../main";
import firebase from "../../db/firebase/MFirebase";
import { limit, where, orderBy } from "firebase/firestore";
import Supplier from "../../types/supplier/supplier";

let listener, bListener;
const initState = {
  sID: undefined,
  supplier: undefined,
  supplierEdit: new Supplier(),
  basedatas: [],
  loaded: {
    basedatas: false,
  },
};

export default {
  namespaced: true,
  name: "supplier",

  state: {
    ...initState,
  },

  mutations: {
    setSupplierID(state, id) {
      state.sID = id;
    },

    setSupplier(state, supplier) {
      state.supplier = supplier ? new Supplier(supplier) : null;
    },

    setBasedatas(state, basedatas) {
      state.basedatas = basedatas;
    },

    setSupplierEdit(state, supplier) {
      if (supplier) state.supplierEdit = new Supplier(supplier);
      else state.supplierEdit = { ...initState.supplierEdit };
    },

    setLoaded(state, { key, value }) {
      state.loaded[key] = value;
    },

    resetState(state) {
      Object.assign(state, initState);
    },
  },

  actions: {
    async setSupplierID({ commit, dispatch }, id) {
      commit("setSupplierID", id);
      if (listener) listener();

      listener = firebase.listenToOne(
        "supplier",
        id,
        (found) => {
          dispatch("setSupplier", found);
        },
        (err) => {
          miError("Supplier Module", err);
        }
      );
    },

    setSupplier({ commit, dispatch }, supplier) {
      if (!supplier) {
        commit("resetState");
        return;
      }

      if (!(supplier instanceof Supplier)) {
        supplier = new Supplier(supplier);
      }

      commit("setSupplier", supplier);
      commit("setSupplierEdit", supplier);
      dispatch("fetchEntries");
    },

    fetchEntries({ state, commit }) {
      if (bListener) bListener();

      commit("setLoaded", { key: "basedatas", value: false });

      if (state.sID) {
        miLog("Listen to Supplier Basedatas", state.sID);
        bListener = firebase.listenTo(
          "basedatas",
          (found) => {
            commit("setBasedatas", found);
            commit("setLoaded", { key: "basedatas", value: true });
          },
          [
            where("suppliers", "array-contains", state.sID),
            orderBy("updated.time", "desc"),
            limit(5),
          ],
          (err) => console.log(err)
        );
      }
    },

    async saveItem({ commit, state }) {
      try {
        // Save the edited supplier data to Firebase
        await firebase.supplier.set(
          state.supplierEdit.toSaveable
            ? state.supplierEdit.toSaveable()
            : state.supplierEdit
        );

        // Commit the mutation to update the supplier in the state
        commit("setSupplier", state.supplierEdit);
      } catch (err) {
        miError("Supplier Module", err);
      }
    },

    updateEdit({ commit, state }, { key, value }) {
      if (key in state.supplierEdit) {
        commit("setSupplierEdit", {
          ...state.supplierEdit,
          [key]: value,
        });
      }
    },

    // Array
    updateItem({ commit, state }, { key, index, item }) {
      const updatedItems = state.supplierEdit[key].map((data, i) =>
        i === index ? item : data
      );
      commit("setSupplierEdit", {
        ...state.supplierEdit,
        [key]: updatedItems,
      });
    },

    addLocation({ commit, dispatch, state }, location) {
      const updatedLocations = [...state.supplierEdit.locations, location];
      commit("setSupplier", {
        ...state.supplierEdit,
        locations: updatedLocations,
      });
      dispatch("update", { locations: updatedLocations });
    },

    deleteLocation({ commit, dispatch, state }, location) {
      const updatedLocations = state.supplierEdit.locations.filter(
        (loc) => loc.id !== location.id
      );
      commit("setSupplier", {
        ...state.supplierEdit,
        locations: updatedLocations,
      });
      dispatch("update", { locations: updatedLocations });
    },

    addContact({ commit, dispatch, state }, contact) {
      contact.sID = state.sID;
      const updatedContacts = [...state.supplierEdit.contacts, contact];
      commit("setSupplier", {
        ...state.supplierEdit,
        contacts: updatedContacts,
      });
      dispatch("update", { contacts: updatedContacts });
    },

    deleteContact({ commit, dispatch, state }, contact) {
      const updatedContacts = state.supplierEdit.contacts.filter(
        (cont) => cont.id !== contact.id
      );
      commit("setSupplier", {
        ...state.supplierEdit,
        contacts: updatedContacts,
      });
      dispatch("update", { contacts: updatedContacts });
    },

    async update({ commit, state }, updatedData) {
      try {
        const supplierDataToUpdate = {};

        Object.keys(updatedData).forEach((key) => {
          const value = updatedData[key];
          if (Array.isArray(value)) {
            supplierDataToUpdate[key] = value.map((item) => {
              return item.toSaveable ? item.toSaveable() : item;
            });
          } else {
            supplierDataToUpdate[key] = value;
          }
        });

        if (Object.keys(supplierDataToUpdate).length > 0) {
          firebase.supplier.update(state.sID, supplierDataToUpdate);
          commit("setSupplier", {
            ...state.supplierEdit,
            ...supplierDataToUpdate,
          });
        }
      } catch (err) {
        miError("Supplier Module", err);
      }
    },

    resetState({ commit }) {
      if (listener) listener();
      if (bListener) bListener();
      commit("resetState");
    },
  },

  getters: {
    getSupplierID: (state) => state.sID,
    getSupplier: (state) => state.supplier,
    getSupplierEdit: (state) => state.supplierEdit,

    getSupplierTitle: (state) => state.supplier?.title,
    getSupplierEditTitle: (state) => state.supplierEdit?.title,

    getSupplierName: (state) => state.supplier?.name,
    getSupplierEditName: (state) => state.supplierEdit?.name,

    getSupplierDesc: (state) => state.supplier?.desc,
    getSupplierEditDesc: (state) => state.supplierEdit?.desc,

    getSupplierWeb: (state) => state.supplier?.web,
    getSupplierEditWeb: (state) => state.supplierEdit?.web,

    getSupplierPhone: (state) => state.supplier?.phone,
    getSupplierEditPhone: (state) => state.supplierEdit?.phone,

    getSupplierMail: (state) => state.supplier?.mail,
    getSupplierEditMail: (state) => state.supplierEdit?.mail,

    getSupplierTags: (state) => state.supplier?.tags,
    getSupplierEditTags: (state) => state.supplierEdit?.tags,

    getLocations: (state) => state.supplier?.locations ?? [],
    getContacts: (state) => state.supplier?.contacts ?? [],

    getBasedatas: (state) => state.basedatas,
    isBasedatasLoaded: (state) => state.loaded.basedatas,
  },
};
