// Base
import { uniq } from "lodash";
import { miError, miLog, miTodo } from "../../../main";

// Firestore
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getCountFromServer,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  startAfter,
  updateDoc,
  where,
  writeBatch,
} from "firebase/firestore";
import { basePaths } from "../MFirepaths";
import { setTimes } from "../MFirebase";

miTodo("IDs", "getLast, getNext, remove...");
let lastDocs = {};

export default class StoreHelper {
  constructor(store) {
    this.store = store;
  }

  // IDs
  getLastID() {}
  getNextID() {}
  removeLastID() {}

  //
  // Add, Set, Update,
  // Remove
  //

  /**
   * Add Item to Firestore
   *
   * @param {String} path to Firestore collection.
   * @param {Object} item to be saved.
   * @return {Promise<DocumentReference<any>>} Docreference.
   */
  async add(path, item) {
    setTimes(item);
    const added = await addDoc(collection(this.store, path), item);
    miLog("Added item", item, "Return", added);
    item.id = added.id;
    return item;
  }

  /**
   * Add items batch to Firestore collection.
   *
   * @param {string} path - Path to Firestore collection.
   * @param {Array<Object>} items - Items to be saved.
   * @returns {Promise<DocumentReference<any>>} - Promise that resolves to a batch write result.
   */
  async addBatch(path, items) {
    const batch = writeBatch();

    const docRef = collection(this.store, path);
    items.forEach((item) => {
      setTimes(item);
      batch.set(docRef, item);
    });

    try {
      miLog("Batch write successful");
      return await batch.commit();
    } catch (error) {
      miError("Error executing batch write:", error);
      return;
    }
  }

  /**
   * Set Item to Firestore
   *
   * @param {String} path to Firestore Doc with ID.
   * @param {Object} item to be saved.
   * @return {Promise<void>} DocReference.
   */
  set(path, item) {
    miLog("FireStore: Set", path, item);
    const mdoc = doc(this.store, path);
    setTimes(item);
    return setDoc(mdoc, item);
  }

  /**
   * Set Items to Firestore
   *
   * @param {String} path to Firestore Doc with ID.
   * @param {Array<{id : string, Object}>} items to be saved.
   * @return {Promise<void>} DocReference.
   */
  async setBatch(path, items) {
    const batch = writeBatch();

    items.forEach((item) => {
      const docRef = doc(this.store, path, item.id);
      setTimes(item);
      batch.set(docRef, item);
    });

    try {
      miLog("Batch write successful");
      return await batch.commit();
    } catch (error) {
      miError("Error executing batch write:", error);
      return;
    }
  }

  /**
   * Update Item in Firestore
   *
   * @param {String} path to Firestore Doc with ID.
   * @param {Object} item to be saved.
   * @return {Promise<void>} DocReference.
   */
  update(path, item) {
    miLog("FireStore: Update", path, item);
    const mref = doc(this.store, path);
    setTimes(item);
    return updateDoc(mref, item);
  }

  /**
   * Update documents batch in Firestore.
   *
   * @param {string} path - Path to Firestore collection.
   * @param {Array<{id: string, data: Object}>} updates - Array of objects containing the document ID and updated data.
   * @returns {Promise<void>} - Promise that resolves when the batch update is completed.
   */
  async updateBatch(path, updates) {
    const batch = writeBatch();

    updates.forEach((update) => {
      const { id, data } = update;
      const docRef = doc(path, id);
      setTimes(data);
      batch.update(docRef, data);
    });

    try {
      await batch.commit();
      miLog("Batch update successful");
    } catch (error) {
      miError("Error executing batch update:", error);
      throw error;
    }
  }

  /**
   * Remove a document from Firestore.
   *
   * @param {string} path - Path to the Firestore document.
   * @param {string} id - ID of the document to be removed.
   * @returns {Promise<void>} - Promise that resolves when the document is successfully deleted.
   */
  async remove(path, id) {
    try {
      await deleteDoc(doc(this.store, path, id));
    } catch (error) {
      miError("Error removing document:", error);
    }
  }

  //
  // Gets
  //

  /**
   * Get an Item from Store
   *
   * @param {String} path
   * @param {String | Number | undefined} id
   * @param {Class} converterClass
   * @returns {Object} Item found
   */
  async getItem(path, id, converterClass) {
    if (!path) throw "FStore: No Path to get Item";
    miLog("FStore", "Get item", path, id, converterClass);
    const mdoc = await getDoc(doc(this.store, path + (id ? id : "")));
    let data = mdoc.data();
    if (converterClass) data = new converterClass(data);
    return data;
  }

  /**
   * Get an Item from Store
   *
   * @param {String} path
   * @param {Object} params
   * @param {Class} converterClass
   * @returns {Object} Item found
   */
  async getItemBy(path, params, converterClass) {
    if (!path) throw "FStore: No Path to get Item";

    miLog("FStore", "Get item", path, params, converterClass);

    let firestoreQuery = query(collection(this.store, path));

    if (params && typeof params === "object") {
      for (const [key, value] of Object.entries(params)) {
        firestoreQuery = where(key, "==", value);
      }
    }

    const mdoc = await getDoc(firestoreQuery);
    let data = mdoc.data();

    if (converterClass) {
      data = new converterClass(data);
    }

    return data;
  }

  /**
   *  Get items from Collection by Query.
   *
   * @param {String} path
   * @param {Array<QueryConstraint>} extras like where(...), limit(x)
   * @param {Class} converterClass
   * @returns {Array<any>}
   */
  async getItems(path, extras, converterClass) {
    if (!extras || !path) throw "Need Statements";
    let result = [];
    let items = await getDocs(query(collection(this.store, path), ...extras));
    items.forEach((it) => {
      let data = it.data();
      if (converterClass) data = new converterClass(data);
      result.push(data);
    });
    return result;
  }

  /**
   *  Get all items from Collection with Limit.
   *
   * @param {String} path
   * @param {Number} mlimit Limit of items
   * @param {Class} converterClass
   * @returns
   */
  async getCollection(path, mlimit, converterClass) {
    const items = await getDocs(
      query(collection(this.store, path), limit(mlimit))
    );
    const array = [];
    items.forEach((it) => {
      let data = it.data();
      if (converterClass) data = new converterClass(data);
      array.push(data);
    });
    return array;
  }

  /**
   *
   * @param {String} path
   * @param {Array<QueryConstraint>} extras
   * @returns
   */
  async getCountsFor(path, extras) {
    if (!path) {
      miError("Firestore", "No path for getCountsFor");
      return;
    }
    // miLog("Firestore", "Get Counts for", path);
    const coll = query(collection(this.store, path), ...extras);
    const snap = await getCountFromServer(coll);
    return await snap.data().count;
  }

  //
  // Listens
  //

  /**
   *  Listen to Changes return Array
   *
   * @param {String} path
   * @param {Function} onFound
   * @param {Array<QueryConstraint>} extras
   * @param {Function} onError
   * @param {Class} converterClass
   * @returns {Unsubscribe} unsub Function
   */
  listenTo(path, onFound, extras, onError, converterClass) {
    miLog("Listen to", path, extras);
    const typeDoc = collection(this.store, path);
    let q;
    if (extras) q = query(typeDoc, ...extras);
    else q = query(typeDoc);
    return onSnapshot(
      q,
      (mdoc) => {
        miLog("Listen to onFound", mdoc);
        const foundArray = [];
        mdoc.forEach((doc) => {
          let data = doc.data();
          data.id = `${doc.id}`;
          if (converterClass) data = new converterClass(data);
          foundArray.push(data);
        });
        onFound(foundArray);
      },
      onError
    );
  }

  /**
   *  Listen to Changes for return Item
   *
   * @param {String} path
   * @param {Function} onFound
   * @param {Function} onError
   * @param {Class} converterClass
   * @returns {Unsubscribe} unsub Function
   */
  listenToOne(path, onFound, onError, converterClass) {
    miLog("Listen to One", path, onFound, onError, converterClass);
    const docRef = doc(this.store, path);
    return onSnapshot(
      docRef,
      (mdoc) => {
        let data = mdoc.data();
        data.id = `${mdoc.id}`;
        if (data && converterClass) data = new converterClass(data);
        onFound(data);
      },
      onError
    );
  }

  /**
   *  Listen to Doc Changes
   *
   * @param {String} path
   * @param {Function} onFound
   * @param {Array<QueryConstraint>} extras
   * @param {Function} onError
   * @param {Class} converterClass
   * @returns {Unsubscribe}
   */
  listenToDoc(path, onFound, extras, onError, converterClass) {
    const typeDoc = doc(this.store, path);
    let q;
    if (extras) q = query(typeDoc, ...extras);
    else q = query(typeDoc);
    return onSnapshot(
      q,
      (mdoc) => {
        let data = mdoc.data();
        if (!data.id) data.id = `${mdoc.id}`;
        if (!converterClass) data = new converterClass(data);
        onFound(data);
      },
      (err) => onError(err)
    );
  }

  //
  // UNSORTED
  //

  async getCompanies(uID, compIDs) {
    const ref = collection(this.store, basePaths.company);
    const mq = query(ref, where("users", "array-contains", uID));

    const querySnapshot = await getDocs(mq);
    const existingCompanies = {};
    querySnapshot.forEach((doc) => {
      const company = doc.data();
      company.id = doc.id;
      existingCompanies[company.id] = company;
    });

    // No ids -> return by ids
    if (!compIDs || (compIDs && compIDs.length == 0)) return existingCompanies;

    try {
      const uniqueIDs = uniq(compIDs.filter((id) => !existingCompanies[id]));

      const newCompanies = {};
      for (const companyID of uniqueIDs) {
        const company = await this.getCompany(companyID);
        if (company) {
          newCompanies[company.id] = company;
        }
      }
      return { ...existingCompanies, ...newCompanies };
    } catch (error) {
      return existingCompanies;
    }
  }

  async sendCompanyRequest(compid, uid, req) {
    const newReq = {
      ...req,
      time: new Date().getTime(),
    };
    miLog("DocPath", "/requests/", compid, uid);
    const ref = doc(this.store, "requests", compid, "users", uid);
    return await setDoc(ref, newReq);
  }

  async checkInvite(companyid, uid) {
    const ref = doc(this.store, "requests", companyid, "users", uid);
    return await getDoc(ref);
  }

  async getRequests(companyid, onFound, onError, extras) {
    const ref = collection(this.store, "requests", companyid, "users");
    if (!onFound) throw "Must include onFound";
    let q;
    if (extras) q = query(ref, ...extras);
    else q = ref;
    return onSnapshot(
      q,
      (found) => {
        const foundArray = [];
        found.forEach((item) => {
          const data = item.data();
          data.id = item.id;
          foundArray.push(data);
        });
        onFound(foundArray);
      },
      (error) => {
        if (onError) onError(error);
      }
    );
  }

  async setRequest(companyID, req) {
    const ref = doc(this.store, "requests", companyID, "users", req.id);
    await setDoc(ref, req);
  }

  async removeRequest(companyID, req) {
    const ref = doc(this.store, "requests", companyID, "users", req.id);
    await deleteDoc(ref);
  }

  /**
   * Queries a Firestore collection based on a list of keys and a search term, and retrieves a set of documents matching the query parameters.
   *
   * @param {string} path - The path to the Firestore collection.
   * @param {*} search - The search term.
   * @param {Array<string>} keys - The keys to search by.
   * @param {boolean} getNext - If true, retrieves the next set of documents in a pagination system.
   * @param {number} mlimit - The maximum number of documents to retrieve.
   * @param {boolean} hideHeadlines - If true, does not add a headline to the results.
   *
   * @returns {Array<object>} An array of documents matching the query parameters. Each document is represented as an object.
   *
   * @throws {Error} If no search term is provided.
   */
  async getItemsBy(path, search, keys, getNext, mlimit, hideHeadlines) {
    // miLog("Getting by ", path, search, keys, getNext);
    if (!search) throw Error("Must have search");

    // Basics
    const array = [];
    const mcollection = collection(this.store, path);
    const isString = isNaN(search);

    // miLog("Getting items", path, search, keys);

    // Key of keys
    for (const key of keys) {
      try {
        // Search Parameters
        const extras = [
          limit(mlimit ? mlimit : 2),
          where(key.toLowerCase(), ">=", isString ? search : 1 * search),
          orderBy(key.toLowerCase()),
        ];

        // get (where < end)
        // String or Num
        let end;
        if (isString) end = endForString(search);
        else end = endForNumber(search);
        extras.push(where(key.toLowerCase(), "<", isString ? end : 1 * end));

        // if get next entries?
        if (getNext && lastDocs[key]) extras.push(startAfter(lastDocs[key]));
        // else push Headline

        // start DB

        const items = await getDocs(query(mcollection, ...extras));
        if (!hideHeadlines && !items.empty && !getNext) {
          array.push({
            title: "Found by " + key,
            header: key,
            isHeadline: true,
          });
        }

        items.forEach((it) => {
          let item = it.data();
          array.push(item);
        });

        // set last doc for getNextEntries
        lastDocs[key] = items.docs[items.size - 1];
      } catch (e) {
        console.debug(e);
      }
      // miLog("DB return", array);
    }

    return array;
  }
}

const endForString = (search) =>
  search.replace(/.$/, (c) => String.fromCharCode(c.charCodeAt(0) + 1));

const endForNumber = (search) => search * 10 + 9;
