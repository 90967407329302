import { cloneDeep } from "lodash";
import { miLog } from "../../main";
import Struct from "./datas/goal.json";
import { Type } from "../Types";

export default class Goal {
  constructor(goal) {
    miLog("Creating goal...", goal);
    if (Struct) {
      Object.assign(this, Struct);
    }
    if (goal) Object.assign(this, goal);
    this.type = new Type("goal");
    miLog("Created goal", this);
  }

  toSaveable() {
    let item = { ...cloneDeep(this) };
    delete item.type;
    return item;
  }
}
