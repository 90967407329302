<template>
  <v-sheet class="print-sheet vertical gapped">
    <div class="header">
      <img class="logo" :src="doc.logo" />
      <v-spacer></v-spacer>
      <span class="type-name">
        {{ doc.typeName() }}<br />
        # {{ doc.id }}
      </span>
    </div>

    <v-divider></v-divider>

    <div class="letter-window">
      <div class="address-window">
        <div class="recipient-address">
          {{ doc.customer.name }}<br />
          {{ anrede }} {{ doc.contact.prename }} {{ doc.contact.surname }}<br />
          {{ doc.location.street }} {{ doc.location.nr }}<br />
          {{ doc.location.zip }} {{ doc.location.city }}
        </div>
      </div>
    </div>

    <!-- Einleitung -->
    <span class="text-area only-print">
      {{ doc.pretext }}
    </span>

    <!-- Items -->
    <v-simple-table class="table" style="overflow: hidden">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="theader">Pos</th>
            <th class="theader">Name / #Art-Nr</th>
            <th class="theader">Anzahl</th>
            <th class="theader">Einzelpreis</th>
            <th class="theader">MwSt</th>
            <th class="theader">Gesamt</th>
          </tr>
        </thead>
        <tbody v-for="(item, i) in doc.positions" :key="`${i} ${item.id}`">
          <tr
            :class="{ 'first-row': true, 'no-border-bottom': true }"
            style="position: relative"
          >
            <td class="item-index">
              {{ i + 1 }}
            </td>
            <td v-if="!item.isFreeText" class="item-title">
              {{ item.title }} <br />
              #{{ item.sku }}
            </td>
            <td v-if="!item.isFreeText" class="item-count">
              {{ item.count }}
            </td>
            <td v-if="!item.isFreeText" class="item-vk">
              {{ item.vk.toMoneyString() }}
            </td>
            <td v-if="!item.isFreeText" class="item-mwst">{{ item.mwst }}%</td>
            <td v-if="!item.isFreeText" class="item-total">
              {{ sum(item).toMoneyString() }}
            </td>
            <td colspan="5" v-if="item.isFreeText" class="item-text">
              {{ item.text }}
            </td>
          </tr>
          <!-- New row for description -->
          <tr class="second-row">
            <td colspan="6" class="item-description">
              {{ item.desc }}
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <!-- Sums -->
    <div class="horizontal" style="margin-top: 1rem">
      <v-spacer></v-spacer>
      <v-card width="40%" style="position: relative" flat>
        <div class="horizontal sum">
          <span class="first" style="width: 100%"> Zwischensumme </span>
          <span class="number">{{ gesVK.toMoneyString() }}</span>
        </div>

        <div
          class="horizontal sum tax"
          v-for="(tax, index) in taxes"
          :key="tax.mwst + '' + index"
        >
          <span class="first" style="width: 100%"> Mehrwertsteuer </span>
          <span class="number tax-menu" style="width: 100%">
            {{ tax.mwst }} %
          </span>
          <span class="number" style="width: 100%">{{
            tax.money.toMoneyString()
          }}</span>
        </div>

        <v-divider></v-divider>

        <div class="horizontal sum">
          <span class="first" style="width: 100%"> Rechnungsbetrag </span>
          <span class="number">{{ ges.toMoneyString() }}</span>
        </div>
        <v-btn
          class="hide-print"
          tabIndex="-1"
          fab
          small
          outlined
          text
          color="white"
          @click="outlinedSum = !outlinedSum"
          style="position: absolute; top: 1rem; right: -5rem"
        >
          <v-icon>mdi-eye</v-icon>
        </v-btn>
      </v-card>
    </div>

    <!-- Footer -->
  </v-sheet>
</template>
<script>
import Doc from "../doc";
export default {
  props: {
    doc: {
      type: Doc,
    },
  },

  computed: {
    items() {
      return this.doc.positions;
    },
    anrede() {
      if (!this.doc.contact) return "";
      return this.doc.contact.sex == "m" ? "Herr" : "Frau";
    },

    ges() {
      let taxSum = 0;
      this.taxes.forEach((it) => {
        taxSum += it.money;
      });
      return this.gesVK + taxSum;
    },

    gesVK() {
      let ges = 0;
      this.items.forEach((it) => {
        ges += this.sum(it);
      });
      return ges;
    },

    gesTax() {
      return this.gesVK * (this.tax / 100);
    },

    taxes() {
      let taxes = {};
      this.items.forEach((it) => {
        if (it.mwst == undefined || it.mwst == null || it.isFreeText) return;
        if (!taxes[it.mwst]) taxes[it.mwst] = 0;

        const sum = Number(it.count) * Number(it.vk);
        const tax = Number(it.mwst / 100) * sum;
        taxes[it.mwst] += tax;
      });

      const keys = Object.keys(taxes).sort((a, b) => b - a);

      return keys.map((it) => {
        return {
          mwst: it,
          money: taxes[it],
        };
      });
    },
  },

  methods: {
    sum(item) {
      const sum = item.vk * item.count;
      if (isNaN(sum)) return 0;
      return sum;
    },
  },
};
</script>
<style lang="scss" scoped>
.print-sheet {
  position: relative;
  width: 21cm;
  min-height: 28.7cm;

  font-family: Arial, sans-serif;
  color: #333;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
  }

  .logo {
    width: 25%;
  }

  .type-name {
    font-size: 1.25rem;
    text-align: right;
  }

  .letter-window {
    margin: 1rem;
    padding: 0.5rem;
    border: 1px solid #ccc;
    width: 4in; /* Adjust the width as needed */
    height: 2in; /* Adjust the height as needed */
    position: relative;
    border-radius: 1rem;
    overflow: hidden;
  }

  .address-window {
    position: absolute;
    bottom: 0.5in; /* Adjust the distance from bottom as needed */
    left: 0.5in; /* Adjust the distance from left as needed */
  }

  .recipient-address {
    font-size: 0.9rem;
  }
  .table {
    position: relative;
    * {
      font-size: 0.75rem;
    }
    font-size: 0.8rem;

    .first-row {
      /* Styles for the first row */
    }

    .no-border-bottom {
      border-bottom: none;
    }

    .item-index {
      width: 80px;
    }

    .item-title {
      width: 100%;
    }

    .item-count {
      min-width: 80px !important;
    }

    .item-vk {
      min-width: 160px;
    }

    .item-mwst {
      /* Styles for item mwst */
    }

    .item-total {
      min-width: 160px;
    }

    .item-text {
      padding-right: 2.5rem;
    }

    .second-row {
      /* Styles for the second row */
    }

    .item-description {
      /* Styles for item description */
    }
  }

  .first-row {
    > * {
      border-bottom: none !important;
    }
  }
  .second-row {
    > * {
      border-bottom: 1px solid lightgrey !important;
    }
  }

  .sum {
    padding: 2px 0.5rem;

    &.tax {
      font-size: 0.8rem;
    }
  }
  .number {
    text-align: right;
  }

  td {
    &.number {
      text-align: center;
    }
    &.money {
      text-align: right;
    }
  }

  .end {
    padding: 0 !important;
  }

  .gapped-not-print {
    gap: 0px;
  }
}
</style>
