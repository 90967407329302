import axios from "axios";

// IMPORTANT!!!!
// STORE SOMEWHERE ELSE!!!
// import { key } from "./key";

const api = axios.create({
  baseURL: "https://api.openai.com/",
  headers: {
    "Content-Type": "application/json",
    // Authorization: `Bearer ` + key,
  },
});

export async function generateText(messages) {
  //Stop bc key
  if (messages) return;
  try {
    const response = await api.post("/v1/chat/completions", {
      model: "gpt-3.5-turbo",
      messages: messages,
    });
    return response.data["choices"][0];
  } catch (error) {
    console.error(error);
    throw error;
  }
}
