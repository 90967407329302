const base = {
  welcome: "Willkommen",
  login: "Anmelden",
  go: "weiter",
  short: "Kürzel",
  edit: "bearbeiten",
  newAccount: "Neuer Account",
  basics: "Generell",
  emptyEntry: "Keine Angaben",
  loading: "Lädt",
  category: "Kategorie",
  color: "Farbe",
  recoms: "Empfehlung",
  due: "Fällig",
  ok: "Ok",
  group: "Gruppe",

  general: "Generell",
  title: "Titel",
  text: "Text",
  desc: "Beschreibung",
  created: "Erstellt",
  updated: "Zuletzt aktualisiert",
  saving: "Speichere",
  takeAndGo: "Übernehmen & weiter",
  website: "Webseite",
  extras: "Extras",
  NA: "Keine Angaben",
  options: "Optionen",
  name: "Name",

  addcompany: "Firma hinzufügen",
  selectCompanyToStart: "Firma auswählen zum starten",
  enterToCreate: "'Enter' zum erstellen.",
  onlySearch: "Nur für Suche",

  new: "Neu",
  more: "Mehr",
  less: "Weniger",

  create: "erstellen",
  select: "auswählen",
  cancel: "abbruch",
  close: "schließen",
  add: "hinzufügen",
  save: "Speichern",
  delete: "löschen",
  deleteSure: "Wirklich löschen",
  deleteEntry: "Eintrag löschen",

  email: {
    base: "E-Mail",
    enter: "E-Mail eingeben",
  },
  phone: {
    base: "Telefon",
    enter: "Nummer eingeben",
  },
  password: {
    base: "Passwort",
    enter: "Passwort eingeben",
  },

  list: {
    empty: "Keine Einträge",
  },

  last: {
    title: "Zuletzt",
    updated: "Zuletzt geändert",
  },

  manu: {
    base: "Hersteller",
    sku: "H-Artikelnummer",
    web: "H-Webseite",
    desc: "H-beschreibung",
  },

  tracking: {
    base: "Tracking",
    none: "Kein Tracking",
    SN: "Seriennummer",
    CH: "Charge",
    CHPre: "Chargen Prefix",
  },

  sizes: {
    base: "Maßen",
    l: "Länge",
    b: "Breite",
    h: "Höhe",
    weight: "Gewicht",

    var: {
      m: "Meter",
      cm: "Zentimeter",
      mm: "Millimeter",
      kg: "Kilogramm",
      g: "Gramm",
      mg: "Milligramm",
    },
  },

  countsHave: "{count} vorhanden",
};

const request = {
  title: "Request",
  create: "Request erstellen",
};

const types = {
  project: {
    title: "Projekt",
    titles: "Projekte",
    infos: "Projektinfos",
    name: "Name des Projekts",
    none: "Kein Projekt",
    nones: "Keine Projekte",
  },
  customer: {
    title: "Kunde",
    titles: "Kunden",
    short: "Kurzname",
    noSelected: "Kein Kunde angegeben",
    none: "Kein Kunde",
    nones: "Keine Kunden",
  },
  supplier: {
    title: "Lieferant",
    titles: "Lieferanten",
    short: "Kurzname",
    noSelected: "Kein Lieferant angegeben",
    none: "Kein Lieferant",
    nones: "Keine Lieferanten",
  },
  basedata: {
    title: "Artikel",
    titles: "Artikel",
    add: "Artikel hinzufügen",
    noSelected: "Kein Artikel angegeben",
    sku: "Artikelnummer",
    tracking: "Tracking",
    sizes: "Maßen",
    quality: "Qualität?",
    none: "Kein Artikel",
    nones: "Keine Artikel",
  },
  contact: {
    title: "Kontakt",
    titles: "Kontakte",
    type: "Kontakttyp",
    short: "Kürzel",
    add: "Kontakt hinzufügen",
    noSelected: "Kein Kontakt angegeben",
    m: "Herr",
    w: "Frau",
    prename: "Vorname",
    surname: "Nachname",
    none: "Kein Kontakt",
    nones: "Keine Kontakte",
  },
  user: {
    title: "User",
    titles: "Users",
    invite: "User einladen",
    short: "Kürzel",
    prename: "Vorname",
    surname: "Nachname",
    noSelected: "Kein User angegeben",
    none: "Kein User",
    nones: "Keine Users",
  },
  location: {
    title: "Standort",
    titles: "Standorte",
    name: "Standortname",
    short: "Kurzname",
    add: "Standort hinzufügen",
    street: "Straße",
    nr: "Nummer",
    zip: "Plz",
    city: "Stadt",
    noSelected: "Kein Standort angegeben",
    none: "Kein Standort",
    nones: "Keine Standorte",
  },

  tag: {
    title: "Tag",
    titles: "Tags",
    add: "Tag hinzufügen",
    none: "Kein Tag",
    nones: "Keine Tags",
  },
  note: {
    title: "Notiz",
    titles: "Notizen",
    none: "Keine Notiz",
    nones: "Keine Notizen",
  },
  todo: {
    title: "Aufgabe",
    titles: "Aufgaben",
    none: "Keine Aufgabe",
    nones: "Keine Aufgaben",
  },
  event: {
    title: "Termin",
    titles: "Termine",
    next: "Nächster Termin",
    nonext: "Kein nächster Termin",
    none: "Kein Termin",
    nones: "Keine Termine",
  },
  doc: {
    title: "Dokument",
    titles: "Dokumente",
    none: "Kein Dokument",
    nones: "Keine Dokumente",
  },
};

const company = {
  title: "Firma",
  multi: "Firmen",
  add: "Firma hinzufügen",
  new: "Neue Firma",
  edit: "Firma bearbeiten",
};

const error = {
  email: {
    invalid: "Ungültige E-Mail-Adresse.",
  },
  userDisabled: "Benutzerkonto ist deaktiviert.",
  userNotFound: "Benutzer nicht gefunden.",
  incorrectPassword: "Falsches Passwort.",
  passwordRequired: "Passwort ist erforderlich.",
  generic: "Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.",
};

export default {
  ...base,
  ...types,
  request,
  error,
  company,
};
