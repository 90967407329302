import Vue from "vue";
import VueI18n from "vue-i18n";

import de from "./defs/de.js";
import ro from "./defs/ro.js";
import en from "./defs/en.js";

Vue.use(VueI18n);
function countToHave(count) {
  return count + " vorhanden";
}

const messages = {
  de,
  en,
  ro,
};

export const locales = Object.keys(messages);

export const i18n = new VueI18n({
  locale: "de",
  messages,
  methods: {
    countToHave,
  },
});

export default i18n;
