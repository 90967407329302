<template>
  <v-app-bar
    app
    class="top-bar"
    :value="!getHideTop"
    flat
    dark
    elevation="0"
    color="transparent"
    style="z-index: 6"
  >
    <div
      class="mi-connected"
      style="background-color: #fefefe20"
      :style="getHideLeft ? 'margin-left: 3rem' : ''"
    >
      <hover-button
        v-show="getBreads.length > 1"
        icon="mdi-chevron-left"
        title="Zurück"
        color="nav"
        :openDelay="1000"
        @click="$router.go(-1)"
      >
      </hover-button>

      <hover-button
        v-for="(bread, i) in getBreads"
        :key="bread.text"
        dark
        :openDelay="1000"
        :icon="bread.icon"
        :title="bread.text"
        :expanded="i == getBreads.length - 1"
        color="transparent"
        @click="
          $router.push({
            name: bread.to.name,
          })
        "
      ></hover-button>
    </div>

    <v-spacer></v-spacer>

    <div class="mi-connected horizontal" v-if="false">
      <div v-if="isSearchRoute" class="horizontal">
        <v-expand-x-transition>
          <div v-if="!getType" class="horizontal">
            <hover-button
              v-for="mtype of types"
              :key="mtype.key"
              :id="mtype.key"
              :icon="mtype.icon"
              :title="mtype.title"
              :openDelay="1000"
              color="transparent"
              @click="setType(mtype)"
            />
          </div>
        </v-expand-x-transition>

        <v-expand-x-transition>
          <div v-if="getType" class="horizontal">
            <con-button
              v-for="mFilterKey of getFilterKeys"
              :key="mFilterKey"
              v-show="!filterKey || filterKey == mFilterKey"
              :id="mFilterKey"
              :title="mFilterKey"
              :openDelay="1000"
              :color="filterKey == mFilterKey ? 'nav' : 'transparent'"
              @click="
                filterKey == mFilterKey
                  ? (filterKey = '')
                  : (filterKey = mFilterKey)
              "
            />
          </div>
        </v-expand-x-transition>

        <v-text-field
          solo
          flat
          placeholder="Suche"
          :backgroundColor="
            filterKey && !filterText ? 'actions' : 'transparent'
          "
          hide-details
          v-model="filterText"
        />
      </div>
      <!-- <hover-button icon="mdi-magnify" color="nav" /> -->
      <con-button
        color="transparent"
        :icon="'mdi-magnify'"
        :disabled="isSearchRoute && (!filterKey || !filterText)"
        @click="
          !isSearchRoute
            ? $router.push({ name: 'SearchPage' })
            : filterKey && filterText
            ? addAndClearFilter()
            : ''
        "
      />
    </div>

    <div
      class="horizontal gapped"
      v-if="!hideAppBarActions"
      :style="getHideRight ? 'margin-right: 3rem' : ''"
    >
      <v-badge
        color="nav"
        :content="$store.state.feedbacks.length"
        :value="$store.state.feedbacks.length"
        top
        overlap
      >
        <v-btn
          large
          icon
          color="white"
          v-if="$store.state.userData?.isDev"
          @click="$store.state.dialogs.dev.show = true"
        >
          <v-icon>mdi-dev-to</v-icon>
        </v-btn>
      </v-badge>

      <!-- Feedback -->
      <!-- 
      <v-btn
        large
        icon
        color="white"
        @click="$store.state.dialogs.feedback.show = true"
      >
        <v-icon>mdi-message-draw</v-icon>
      </v-btn> -->
    </div>
  </v-app-bar>
</template>
<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { Type, TypesFavs } from "../../../../types/Types";
import ConButton from "../../../components/connected/ConButton.vue";
import HoverButton from "../../../components/connected/HoverButton.vue";

export default {
  components: { HoverButton, ConButton },

  data() {
    return {
      types: TypesFavs.map((it) => new Type(it)),
      filterText: "",
      filterKey: "",
    };
  },

  props: {},
  mounted() {},

  computed: {
    ...mapState("appbar", ["disableSideSwitch", "hideAppBarActions"]),
    ...mapGetters(["getCurrentItem"]),
    ...mapGetters("appbar", [
      "getBreads",
      "getHideTop",
      "getHideLeft",
      "getHideRight",
    ]),
    ...mapGetters("miuser", ["getUserName", "img"]),
    ...mapGetters("company", ["getCompanyName"]),
    ...mapGetters("dialog", ["getDialogShow"]),
    ...mapGetters("search", ["getType", "getFilterKeys"]),

    isSearch() {
      return this.$route.name == "Liste";
    },

    showActions() {
      return this.search.hover || this.search.filter || this.search.text;
    },
    isSearchRoute() {
      return this.$route.name == "SearchPage";
    },
  },

  methods: {
    ...mapMutations("appbar", ["setHideSide"]),
    ...mapMutations("dialog", ["setShow"]),
    ...mapActions("dialog", ["showDial"]),
    ...mapActions("search", ["setType", "addFilter"]),

    addAndClearFilter() {
      this.addFilter({
        key: this.filterKey,
        value: this.filterText,
      });
      this.filterKey = "";
      this.filterText = "";
    },
  },
};
</script>
<style lang="scss" scoped>
* {
  transition: 1s all cubic-bezier(0.075, 0.82, 0.165, 1);
}
.top-bar {
  // border-bottom: 1px dashed white !important;
  @media print {
    display: none;
  }
}
</style>
