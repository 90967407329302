import { cloneDeep } from "lodash";
import { TypeKeys } from "../../types/Types";
import firebase from "../../db/firebase/MFirebase";
import { miLog, miTodo } from "../../main";
import { limit, orderBy, where } from "firebase/firestore";

// For Type in Types
// {type}s : []
const initState = {
  userviewId: undefined,
  filterString: "",
  selected: "list",
  show: "title",

  comID: undefined,

  counts: {},
  openProjects: [],
  loaded: {},
};

TypeKeys.forEach((it) => {
  initState[it] = null;
  initState.counts[it] = null;
  initState.loaded[it] = false;
  initState[it + "s"] = [];
});

let listeners = [];

export default {
  namespaced: true,
  name: "context",

  state: {
    ...cloneDeep(initState),
  },

  mutations: {
    setSelected(state, key) {
      state.selected = key;
    },

    setShow(state, key) {
      state.show = key;
    },

    setUserviewId(state, user) {
      state.userviewId = user;
    },

    setFilterString(state, filterString) {
      state.filterString = filterString;
    },

    setCount(state, { key, count }) {
      state.counts[key] = count;
    },

    setLoaded(state, { key, value }) {
      state.loaded[key] = value;
    },

    setLasts(state, { key, lasts }) {
      miLog("Context", "Set last ", key, lasts);
      state[key] = lasts;
    },

    setArray(state, { key, array }) {
      state[key] = array;
    },

    resetState(state) {
      Object.assign(state, cloneDeep(initState));
    },
  },

  actions: {
    setSelected({ commit }, key) {
      commit("setSelected", key);
    },

    setShow({ commit }, key) {
      commit("setShow", key);
    },

    setFilterString({ commit }, string) {
      commit("setFilterString", string);
    },

    selectUserview({ commit }, id) {
      miTodo("selectUserview", id);
      commit("setUserviewId", id);
    },

    async listenToAllUsers({ commit }) {
      miLog("Context", "Listen to users");
      commit("setLoaded", { key: "user", value: false });
      firebase.listenTo(
        "user",
        (users) => {
          miLog("Context", "Setting users", users);
          commit("setLasts", {
            key: "user" + "s",
            lasts: users,
          });
        },
        null,
        (err) => miLog(err)
      );
      commit("setLoaded", { key: "user", value: true });
    },

    async fetchAllLasts({ commit }) {
      // For Types
      // Disable old Listeners
      try {
        listeners.forEach((it) => it());
      } catch (e) {
        //
      }

      TypeKeys.forEach(async (it) => {
        // Fix for Workers
        if (it == "user") return;

        miLog("Getting lasts", it);
        commit("setLoaded", { key: it, value: false });
        listeners.push(
          firebase.listenTo(
            it,
            (found) => {
              miLog("FOUND", found);
              commit("setLasts", {
                key: it + "s",
                lasts: found,
              });
              commit("setLoaded", { key: it, value: true });
            },
            [limit(5), orderBy("updated.time", "desc")]
          )
        );
      });
    },

    async fetchAllCounts({ dispatch }) {
      TypeKeys.forEach(async (it) => {
        if (it) dispatch("fetchCount", it);
      });
    },

    async fetchCount({ commit }, key) {
      commit("setCount", {
        key: key,
        count: await firebase.getCountsFor(key),
      });
    },

    async fetchOpenProjects({ commit }) {
      const items = await firebase.getItems("project", [
        where("finished", "!=", true),
      ]);
      commit("setArray", { key: "openProjects", array: items });
      commit("setCount", { key: "openProjects", count: items.length });
    },

    resetState({ commit }) {
      commit("resetState");
    },
  },

  getters: {
    getSelected: (state) => state.selected,
    isSelected: (state) => (selected) => state.selected == selected,
    isShow: (state) => (show) => state.show == show,

    getOpenProjects: (state) => state.openProjects,
    getOpenProjectCount: (state) => state.counts.openProjects,

    getLoaded: (state) => (key) => state.loaded[key] || false,

    getCount: (state) => (key) => state.counts[key],
    getCounts: (state) => state.counts,

    getFilterString: (state) => state.filterString,

    users: (state) => state.users || [],

    hasItems: (state, getters) => (key) =>
      getters?.getItemsFiltered(key)?.length > 0 || false,

    getItemsFiltered: (state) => (key) => {
      if (state.filterString && state[key + "s"]) {
        return state[key + "s"].filter((it) => {
          if (!it[state.show]) return;
          if (typeof it[state.show] === "number") {
            return it[state.show].toString().includes(state.filterString);
          } else {
            const filterString = state.filterString.toLowerCase();
            const itemValue = it[state.show]?.toString().toLowerCase();
            return itemValue.includes(filterString) || false;
          }
        });
      }
      return state[key + "s"];
    },
  },
};
