import { cloneDeep } from "lodash";
import firebase from "../../db/firebase/MFirebase";
import { Type } from "../Types";
import Struct from "./datas/basedata.json";

export const BasedataStruct = Struct;

export default class Basedata {
  constructor(basedata) {
    if (Struct) {
      Object.assign(this, Struct);
    }
    if (basedata) {
      Object.assign(this, basedata);
    }
    this.type = new Type("basedata");
  }

  toSmall() {
    let item = this.toSaveable();
    delete item.supplier;
    delete item.discounts;
    delete item.docs;
    delete item.dim;
    return item;
  }

  // DB
  toSaveable() {
    let item = { ...cloneDeep(this) };
    delete item.type;
    return item;
  }

  async add(onAdd) {
    let item = { ...this.toSaveable() };
    item = await firebase.addItem(this.type.key, item, onAdd);
    this.id = item.id;
    this.created = item.created;
  }

  async save() {
    let item = { ...this.toSaveable() };
    await firebase.setItem(this.type.key, item);
  }
  delete() {}
}
