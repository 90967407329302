<template>
  <v-dialog
    class="midial"
    v-model="showDial"
    :width="width"
    :max-width="maxWidth"
    :scrollable="scrollable"
    :fullscreen="fullscreen"
    :persistent="persistent"
    :overlay="overlay"
    overlay-opacity="0.95"
    transition="dialog-transition"
    style="border-radius: 2rem"
  >
    <slot name="base" :item="item">
      <v-card
        class="card"
        color="primary"
        style="border-radius: 2rem"
        :loading="loading"
      >
        <slot :item="item">
          <v-btn
            v-if="!hideClose"
            fab
            small
            color="onBack"
            absolute
            icon
            @click.stop="!loading && (showDial = false)"
            style="top: 8px; right: 8px"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>

          <v-card-title class="horizontal gapped half">
            <slot name="icon">
              <v-icon>{{ icon ? icon : item?.type?.icon }}</v-icon>
            </slot>
            <slot name="title" :item="item">
              {{ title ? title : $t(`${item.type.key}.title`) }}
            </slot>
          </v-card-title>

          <v-card-subtitle v-if="subtitle">
            <slot name="subtitle" :item="item">
              {{ subtitle }}
            </slot>
          </v-card-subtitle>

          <v-card-text>
            <div class="m-bg-white vertical gapped">
              <slot name="text" :item="item">
                Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                Ratione, enim?
              </slot>
            </div>
          </v-card-text>

          <slot name="actions" :item="item">
            <v-card-actions>
              <v-btn
                color="white"
                text
                :disabled="loading"
                rounded
                @click="showDial = false"
              >
                {{ $t("cancel") }}
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                color="white"
                v-if="!hideSave"
                rounded
                outlined
                :disabled="loading || saveDisabled"
                @click="
                  $emit('save');
                  if (getDialogOnSave(dialKey)) getDialogOnSave(dialKey)(item);
                "
              >
                {{ $t("save") }}
              </v-btn>
            </v-card-actions>
          </slot>
        </slot>
      </v-card>
    </slot>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    dialKey: {
      type: String,
    },
    title: {
      type: String,
    },
    subtitle: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
    },

    width: {
      type: String,
    },

    saveDisabled: {
      type: Boolean,
      default: false,
    },

    scrollable: {
      type: Boolean,
      default: false,
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
    persistent: {
      type: Boolean,
      default: false,
    },
    overlay: {
      type: Boolean,
      default: false,
    },
    hideSave: {
      type: Boolean,
      default: false,
    },
    hideClose: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    maxWidth: {
      type: String,
      default: "500px",
    },
  },

  data() {
    return {};
  },

  computed: {
    ...mapGetters("dialog", [
      "getDialogShow",
      "getDialogData",
      "getDialogOnSave",
    ]),

    showDial: {
      get() {
        return this.getDialogShow(this.dialKey);
      },
      set(value) {
        if (value) this.showDialog(this.dialKey);
        else this.hideDialog(this.dialKey);
      },
    },

    item() {
      return this.getDialogData(this.dialKey);
    },
  },

  methods: {
    ...mapActions("dialog", ["showDialog", "hideDialog"]),
  },

  watch: {
    getDialogData: {
      deep: true,
      handler(value) {
        this.$emit("dataChanged", value);
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.midial {
  border-radius: 2rem !important;
}

.m-bg-white {
  border-radius: 2rem;
  padding: 0.25rem 1.25rem;

  // border-top-left-radius: 2rem;
  // border-top-right-radius: 2rem;
}
</style>
