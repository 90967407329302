import { miLog } from "../../main";
import { setOnBack } from "../../router";

export const Dialogs = {
  accounts: "accounts",
  project: "project",
  customer: "customer",
  supplier: "supplier",
  basedata: "basedata",
  user: "user",
  contact: "contact",
  dev: "dev",
  newVersion: "newVersion",
  feedback: "feedback",
  event: "event",
  eventEdit: "eventEdit",
  company: "company",
  location: "location",
  workerInfo: "workerInfo",
  todoInfo: "todoInfo",
  materialInfo: "materialInfo",
  requests: "requests",
  logo: "logo",
  add: "add",
  todo: "todo",
  request: "request",
  note: "note",
  tags: "tags",
  form: "form",
};

const initState = {};

const initObj = {
  show: false,
  data: undefined,
  onSave: undefined,
};

Object.keys(Dialogs).forEach((key) => {
  initState[key] = { ...initObj };
});

export default {
  namespaced: true,
  name: "dialog",

  state: {
    currentDialogKey: "",

    ...initState,
  },

  mutations: {
    setShow(state, { key, value }) {
      state[state.currentDialogKey] = { ...initObj };
      if (!state[key]) state[key] = { ...initObj };
      state[key].show = value;
    },
    setData(state, { key, data }) {
      if (!state[key]) state[key] = { ...initObj };
      state[key].data = data;
    },
    setOnSave(state, { key, onSave }) {
      if (!state[key]) state[key] = { ...initObj };
      state[key].onSave = onSave;
    },
  },

  actions: {
    switchDialog({ commit, dispatch }, { key, data, onSave }) {
      miLog("Switching show Dialog", key, data);
      commit("setOnSave", { key, onSave });
      commit("setData", { key, data });
      commit("setShow", { key, value: !!data });
      if (data) {
        setOnBack(() => {
          dispatch("hideDialog", key);
          return true;
        });
      }
    },

    showDialog({ commit, dispatch }, { key, data, onSave }) {
      miLog("Setting showDialog", key, data);
      commit("setData", { key, data });
      commit("setOnSave", { key, onSave });
      commit("setShow", { key, value: true });
      setOnBack(() => {
        dispatch("hideDialog", key);
        return true;
      });
    },

    hideDialog({ commit }, key) {
      miLog("Setting hideDialog", key);
      if (typeof key !== "string") {
        throw new Error("Invalid hideDialog key");
      }

      commit("setShow", { key, value: false });
      commit("setData", { key, data: null });
      commit("setOnSave", { key, onSave: null });
    },

    setDialogData({ commit }, { key, data }) {
      commit("setData", { key, data });
    },
  },

  getters: {
    getDialog: (state) => (dialKey) => state[dialKey],
    getDialogShow: (state) => (dialKey) => state[dialKey].show,
    getDialogData: (state) => (dialKey) => state[dialKey].data,
    getDialogOnSave: (state) => (dialKey) => state[dialKey].onSave,
    getDialogAllSet: (state) => (dialKey) => state[dialKey].allSet,
    getDialogState: (state) => state,
  },
};
