// import { miLog } from "../../main";

import firebase, {
  miUser,
  setCurrentCompanyID,
} from "../../db/firebase/MFirebase";
import { miError, miLog } from "../../main";
import { Type } from "../../types/Types";
import Company from "../../types/company/company";

let listener;
const initFilter = {
  project: null,
  customer: null,
  supplier: null,
  basedata: null,
};

export let currentCompId;
export let currentCompany;

export default {
  namespaced: true,
  name: "company",

  state: {
    loading: true,
    comID: undefined,
    company: undefined,

    filterList: [],
    filter: {
      ...initFilter,
    },

    comIDs: [],
    companies: [],
    cLoaded: false,
  },

  mutations: {
    SET_COMPANY_NAME(state, name) {
      state.company.name = name;
    },
    SET_COMPANY_LOCATION(state, location) {
      state.company.location = location;
    },

    setLoaded(state, loaded) {
      state.cLoaded = loaded;
    },

    setComID(state, comID) {
      miLog("setComID", comID);
      setCurrentCompanyID(comID);
      currentCompId = comID;
      state.comID = comID;
      state.cLoaded = true;
    },

    setCompany(state, company) {
      miLog("setCompany", company);
      state.company = company;
      state.comID = company?.id;
      currentCompany = company;
    },

    setCompanies(state, companies) {
      miLog("setCompanies", companies);
      state.companies = companies;
      state.comIDs = Object.keys(companies);
    },

    addCompany(state, company) {
      state.companies[company.id] = company;
      state.comIDs = Object.keys(state.companies);
    },

    setCompanyIds(state, comIDs) {
      miLog("setComIDs", comIDs);
      state.comIDs = comIDs;
    },

    // Filter Mutations
    setFilterType(state, type) {
      state.filter = { ...initFilter };
      state.filter[type.key] = {};
      state.filter[type.key][type.searchKeys] = null;
    },

    addFilterType(state, type) {
      if (!state.filter[type.key]) state.filter[type.key] = {};
      type.searchKeys.forEach((it) => {
        state.filter[type.key][it] = null;
      });
    },

    removeFilterType(state, type) {
      state.filter[type.key] = null;
    },

    // Tags
    setCompanyTags(state, tags) {
      state.company.tags = tags;
    },

    addCompanyTag(state, tag) {
      state.company.tags.push(tag);
    },

    removeCompanyTag(state, tag) {
      const index = state.company.tags.indexOf(tag);
      if (index !== -1) {
        state.company.tags.splice(index, 1);
      }
    },

    // Groups
    setCompanyGroupsUsers(state, users) {
      state.company.groups.users = users;
    },

    setCompanyGroupsBasedata(state, basedata) {
      state.company.groups.basedata = basedata;
    },

    // Favs
    setFavLogo(state, logo) {
      state.company.files.favs.doc.logo = logo;
    },
  },

  actions: {
    // Setters and Updaters

    // Sets the 'loading' state property to indicate if the company is loaded
    setCLoaded({ commit }, loaded) {
      commit("setLoaded", loaded);
    },

    // Sets the current company ID and starts listening for company changes
    setCompanyID({ state, commit }, comID) {
      miLog("CompModule", "Setting Company ID", comID);
      commit("setComID", comID);
      if (comID && state.company?.id != comID) {
        if (listener) listener();
        listener = firebase.company.listenToCompany(
          comID,
          (company) => {
            commit("setCompany", new Company(company));
          },
          (err) => {
            commit("setCompany", null);
            miLog(err);
          }
        );
      }
    },

    // Selects a company by its ID and sets it as the current company
    selectCompany({ dispatch }, company) {
      miLog("CompModule", "Select Company", company);
      if (!company?.id) throw "Selected Company has no ID";
      dispatch("setCompanyID", company.id);
    },

    // Retrieves the list of companies for a user and updates the state
    async getCompanies({ commit }, user) {
      if (!user) throw "No User";
      let companies = await firebase.company.getCompanies(
        user.id,
        user.companies
      );
      commit("setCompanies", companies);
      return companies;
    },

    // Creates a new company and adds it to the state
    async createCompany({ dispatch }, comp) {
      let mcomp = await firebase.company.createCompany(comp);
      dispatch("addCompany", comp);
      return mcomp;
    },

    // Adds a new company to the state
    addCompany({ commit }, company) {
      commit("addCompany", company);
    },

    // Saves the company data to the database
    async saveCompany({ commit }, { user, company }) {
      miLog("Saving Company", user, company);
      if (!company) return;

      const saveableCompany = company?.toSaveable
        ? company.toSaveable()
        : company;

      if (!company.id) {
        miLog("Creating Company", company);
        let comp = await firebase.company.createCompany(saveableCompany);
        commit("setCompany", comp);

        return comp;
      }

      firebase.company.saveCompany(saveableCompany);
      miLog("Saved Company", company);
      return company;
    },

    // Updates the company data in the database
    updateCompany({ state }, company) {
      firebase.company.update({
        id: state.comID,
        ...company,
      });
    },

    // Tags

    // Sets the company tags and updates the database
    setCompanyTags({ commit, dispatch }, tags) {
      commit("setCompanyTags", tags);
      dispatch("updateCompany", { tags: tags });
    },

    // Adds a new tag to the company and updates the database
    addCompanyTag({ commit, dispatch, state }, tag) {
      if (!state.company.tags.includes(tag)) {
        commit("addCompanyTag", tag);
        dispatch("updateCompany", { tags: state.company.tags });
      }
    },

    // Removes a tag from the company and updates the database
    removeCompanyTag({ commit, dispatch, state }, tag) {
      if (state.company.tags.includes(tag)) {
        commit("removeCompanyTag", tag);
        dispatch("updateCompany", { tags: state.company.tags });
      }
    },

    // Groups

    // Groups Users
    // Sets the users in the company groups and updates the database
    async setCompanyGroupsUsers({ commit, state, dispatch }, users) {
      const existingBasedata = state.company.groups.basedata || [];
      commit("setCompanyGroupsUsers", users);
      dispatch("updateCompany", {
        groups: {
          users: users,
          basedata: existingBasedata,
        },
      });
    },

    // Add user to company groups
    async addCompanyGroupsUsers({ commit, state, dispatch }, user) {
      const existingUsers = state.company.groups.users || [];
      const updatedUsers = [...existingUsers, user];
      commit("setCompanyGroupsUsers", updatedUsers);
      dispatch("updateCompany", {
        groups: {
          users: updatedUsers,
          basedata: state.company.groups.basedata,
        },
      });
    },

    // Remove user from company groups
    async removeCompanyGroupsUsers({ commit, state, dispatch }, user) {
      const existingUsers = state.company.groups.users || [];
      const updatedUsers = existingUsers.filter((u) => u.id !== user.id);
      commit("setCompanyGroupsUsers", updatedUsers);
      dispatch("updateCompany", {
        groups: {
          users: updatedUsers,
          basedata: state.company.groups.basedata,
        },
      });
    },

    // Groups Basedata
    // Sets the basedata in the company groups and updates the database
    async setCompanyGroupsBasedata({ commit, state, dispatch }, basedata) {
      const existingUsers = state.company.groups.users || [];
      commit("setCompanyGroupsBasedata", basedata);
      dispatch("updateCompany", {
        groups: {
          users: existingUsers,
          basedata: basedata,
        },
      });
    },

    // Add basedata to company groups
    async addCompanyGroupsBasedata({ commit, state, dispatch }, basedata) {
      const existingBasedata = state.company.groups.basedata || [];
      const updatedBasedata = [...existingBasedata, basedata];
      commit("setCompanyGroupsBasedata", updatedBasedata);
      dispatch("updateCompany", {
        groups: {
          users: state.company.groups.users,
          basedata: updatedBasedata,
        },
      });
    },

    // Remove basedata from company groups
    async removeCompanyGroupsBasedata({ commit, state, dispatch }, basedata) {
      const existingBasedata = state.company.groups.basedata || [];
      const updatedBasedata = existingBasedata.filter(
        (b) => b.id !== basedata.id
      );
      commit("setCompanyGroupsBasedata", updatedBasedata);
      dispatch("updateCompany", {
        groups: {
          users: state.company.groups.users,
          basedata: updatedBasedata,
        },
      });
    },

    // Favs

    // Sets the favorite logo and updates the company in the database
    setFavLogo({ state, commit, dispatch }, logo) {
      commit("setFavLogo", logo);
      dispatch("saveCompany", { company: state.company });
    },

    // Updates the recent favorites and updates the company in the database
    updateCompanyRecents({ state, dispatch }, recents) {
      const favorites = { ...state.company.favorites };
      if (!favorites[miUser.id]) favorites[miUser.id] = {};
      favorites[miUser.id].recents = recents || [];
      dispatch("updateCompany", { favorites: favorites });
    },

    // Updates the bookmarks favorites and updates the company in the database
    updateCompanyBookmarks({ state, dispatch }, bookmarks) {
      const favorites = { ...state.company.favorites };
      if (!favorites[miUser.id]) favorites[miUser.id] = {};
      favorites[miUser.id].bookmarks = bookmarks || [];
      miLog("Update Company Bookmarks", bookmarks, favorites);
      dispatch("updateCompany", { favorites: favorites });
    },

    // Updates the pinned favorites and updates the company in the database
    updateCompanyPinned({ state, dispatch }, pinned) {
      const favorites = { ...state.company.favorites };
      if (!favorites[miUser.id]) favorites[miUser.id] = {};
      favorites[miUser.id].pinned = pinned || [];
      dispatch("updateCompany", { favorites: favorites });
    },

    // Filter

    // Sets the filter type and initializes the filter object
    setFilterType({ commit }, { type }) {
      if (!(type instanceof Type))
        return miError("CompMod", "type is not Type", type);
      commit("setFilterType", type);
    },

    // Toggles the filter type and updates the filter object
    toggleFilterType({ commit, state }, type) {
      const fullType = new Type(type);
      if (!isFilterByKey(state.filter, type)) {
        commit("addFilterType", fullType);
        // this.setSelected("project");
      } else {
        commit("removeFilterType", fullType);
      }
    },

    // Adds a filter type to the filter object
    addFilterType({ commit }, type) {
      if (!(type instanceof Type)) type = new Type(type);
      commit("addFilterType", type);
    },

    // Removes a filter type from the filter object
    removeFilterType({ commit }, type) {
      if (!(type instanceof Type)) type = new Type(type);
      commit("removeFilterType", type);
    },
  },

  getters: {
    getCompanyID: (state) => state.comID,
    getCompany: (state) => state.company,
    getCompanyName: (state) => state.company.name || "Firma",

    getProjectTags: (state) => state.company.tags.projects,

    getLogos: (state) => state.company.files.logos,
    getFavLogo: (state) => state.company.files.favs.doc.logo,

    companies: (state) => state.companies || {},
    hasCompanies: (state) => Object.values(state.companies).length > 0,
    companyIDs: (state) => state.comIDs || [],
    getCount: (state) => state.count,
    getIsAdmin: (state) => (uid) =>
      state.company?.admins?.findIndex((it) => uid == it) > -1 || [],
    getIsUser: (state) => (uid) =>
      state.company?.users?.findIndex((it) => uid == it) > -1 || [],

    getFilter: (state) => state.filter,
    getFilterKeys: (state) =>
      Object.keys(state.filter).filter((it) => state.filter[it] != null),
    hasFilter: (state) => Object.values(state.filter).some((it) => it !== null),
    cLoaded: (state) => state.cLoaded,

    getCompanyGroupsUsers: (state) => state.company.groups.users || [],
    getCompanyGroupsBasedata: (state) => state.company.groups.basedata || [],
  },
};

export function isFilterByKey(filter, key) {
  return filter[key] != null;
}
