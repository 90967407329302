import { miLog } from "../../main";
import Contact from "../../types/contact/contact";
import Customer from "../../types/customer/customer";
import Project from "../../types/project/project";

const classes = {
  project: Project,
  customer: Customer,
  location: Location,
  contact: Contact,
};

export default class MiContext {
  project = undefined;
  customer = undefined;
  location = undefined;
  contact = undefined;

  hasContext = false;
  childs = {};
  current = null;

  currentType = () => {
    if (this.current) return this.current.type;
    else undefined;
  };

  key = null;
  keys = {};

  // Start
  constructor(item) {
    this.setContext(item);
  }

  // Getters
  hasContextFor(key) {
    return this.hasContext && this[key];
  }

  emptyContext(key) {
    miLog("Getting empty Context", key, this.hasContext, !this[key]);
    return this[key] == null || this[key] == undefined;
  }

  // Set / Reset
  setContext(item) {
    this.keys = {};
    this.current = undefined;
    this.hasContext = false;

    for (const key in classes) {
      const convertedKey = `${key.charAt(0).toLowerCase()}ID`;
      this.key = undefined;
      this[key] = undefined;
      this.keys[key] = false;
      this[convertedKey] = undefined;
      this.childs[key] = [];

      if (item) {
        if (item instanceof classes[key]) {
          miLog("FOUND CONTEXT", item);
          this.current = item;
          this.key = key;
          this[key] = item;
          this.keys[key] = true;
          this[convertedKey] = item.id;
          this.hasContext = true;
          miLog("Has context", this.hasContext);
        }

        if (item[key]) {
          this[key] = item[key];
          this.keys[key] = true;
          this.childs[key].push({ ...item[key] });
        }
      }
    }
  }

  reset() {
    this.hasContext = false;
    this.setContext();
  }
}
