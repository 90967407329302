/** @format */

import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import structs from "@/_tosort/structs";
import { clone } from "lodash";
// import { miLog } from "../main";
import appbarModule from "./modules/appbarModule";
import miuserModule from "./modules/miuserModule";
import workerModule from "./modules/workerModule";
import companyModule from "./modules/companyModule";
import dialogModule from "./modules/dialogModule";
import devModule from "./modules/devModule";
import projectModule from "./modules/projectModule";
import customerModule from "./modules/customerModule";
import contextModule from "./modules/contextModule";
import favoritesModule from "./modules/favoritesModule";
import searchModule from "./modules/searchModule";
import supplierModule from "./modules/supplierModule";
import basedataModule from "./modules/basedataModule";
import docModule from "./modules/docModule";
import aiModule from "./modules/aiModule";
import dashModule from "./modules/dashModule";

import firebase from "../db/firebase/MFirebase";
import MiUser from "../types/user/MiUser";
import MiEvent from "../types/event/mievent";
import { limit, where } from "firebase/firestore";
import { miLog } from "../main";
import MiContext from "../db/classes/Context";
import Company from "../types/company/company";
import langModule from "./modules/langModule";

let listener, listenerData, listenerCompany;

// Buh
export const MODES = {
  CONNECTING: "Connecting...",
  LUSER: "Lade User",
  LUDATA: "Lade Userdaten",
  LCOMPANY: "Lade Firmendaten",
};

export const modules = {
  dev: devModule,
  miuser: miuserModule,
  worker: workerModule,
  appbar: appbarModule,
  dialog: dialogModule,
  project: projectModule,
  company: companyModule,
  context: contextModule,
  favorites: favoritesModule,
  search: searchModule,
  customer: customerModule,
  supplier: supplierModule,
  basedata: basedataModule,
  doc: docModule,
  lang: langModule,
  ai: aiModule,
  dash: dashModule,
};

const state = {
  window: {
    width: undefined,
    height: undefined,
  },
  lang: {
    locale: "de",
    locales: ["de", "en", "ro"],
  },
};

const vuex = new Vuex.Store({
  namespaced: true,

  modules,

  state: {
    ...state,
    // Basics
    context: new MiContext(),
    currentItem: null,
    currentTime: Date.now(),
    loading: MODES.CONNECTING,

    // Setup
    core: {
      user: undefined,
      company: undefined,
    },

    // Datas
    printDoc: null,

    currentEvent: null,
    events: [],
    recentItems: [],
    pinnedItems: [],
    bookmarks: [],

    // Types
    counts: {
      projects: 0,
      customers: 0,
      suppliers: 0,
      users: 0,
      basedatas: 0,
    },

    projects: [],
    customers: [],
    suppliers: [],
    users: [],
    basedatas: [],
    files: [],
    todos: [],

    // Helpers
    newVersion: {},
    feedbacks: [],

    // unsorted
    infos: {
      focus: undefined,
    },

    calendar: {
      show: false,
      event: {
        show: false,
        selected: null,
      },
    },

    quickAction: {
      customer: undefined,
      actions: [],
    },

    currentPathName: undefined,

    // Tags / Grouping
    tags: {
      project: ["PV", "WP"],
      customer: [],
      products: [],
      users: [],
      suppliers: [],
    },

    groups: {
      basedata: [
        {
          ...clone(structs.basedataGroup),
          id: 1,
          title: "Solaranlagen",
          icon: "mdi-solar-panel-large",
        },
        {
          ...clone(structs.basedataGroup),
          id: 2,
          title: "Speicher",
          icon: "mdi-home-battery",
        },
        {
          ...clone(structs.basedataGroup),
          id: 3,
          title: "Kabel",
          icon: "mdi-cable-data",
        },
      ],

      user: [
        {
          title: "Leitung",
          icon: "mdi-glasses",
        },
        {
          title: "User",
          icon: "mdi-account-hard-hat",
        },
      ],

      appRoles: [
        {
          title: "Admin",
          icon: "mdi-shield-crown",
        },
        {
          title: "Standard",
          icon: "mdi-account-hard-hat",
        },
      ],
    },

    suggestions: {
      customer: {
        titles: ["Hauptkontakt", "Vertrieb", "Einkauf", "Support"],
      },
      location: {
        titles: ["Hauptstandort", "Privat", "Verwaltung", "Lager"],
      },
    },

    customerTitles: ["Hauptkontakt", "Vertrieb", "Einkauf", "Support"],
    locationTitles: ["Hauptstandort", "Privat", "Verwaltung", "Lager"],

    dimEinheiten: [
      {
        title: "Meter",
        value: "m",
      },
      {
        title: "Zentimeter",
        value: "cm",
      },
      {
        title: "Millimeter",
        value: "mm",
      },
    ],
  },

  mutations: {
    // Basics
    setCurrentTime(state, time) {
      state.currentTime = time;
    },
    setWindowSize(state, { width, height }) {
      state.window.width = width;
      state.window.height = height;
    },
    setLoading(state, value) {
      state.loading = value;
    },
    setCurrentItem(state, item) {
      miLog("Setting current item ", item);
      // if (state.currentItem == item) return;
      state.currentItem = item;
    },

    // Setup
    setUser(state, value) {
      if (value) value = new MiUser(value);
      state.core.user = value;
    },
    setCompany(state, value) {
      if (value) value = new Company(value);
      state.core.company = value;
    },

    // Context
    setContext(state, item) {
      if (!(item instanceof MiContext)) item = new MiContext(item);
      miLog("Current Context", item);
      // state.context.setContext(item);
    },

    resetContext(state) {
      state.context.reset();
    },

    //
    // Datas
    //

    // Counts
    SET_COUNT(state, { key, count }) {
      state.counts[key] = count;
    },

    // Recent
    setRecentItems(state, items) {
      state.recentItems = items;
    },
    addToRecentItems(state, item) {
      state.recentItems.push(item);
    },
    removeFromRecentItems(state, item) {
      const index = state.recentItems.findIndex(
        (recent) => recent.id === item.id && recent.type === item.type
      );
      if (index !== -1) {
        state.recentItems.splice(index, 1);
      }
    },

    // Pinned Items
    setPinnedItems(state, items) {
      state.pinnedItems = items;
    },
    addToPinnedItems(state, item) {
      state.pinnedItems.push(item);
    },
    removeFromPinnedItems(state, item) {
      const index = state.pinnedItems.findIndex(
        (pinned) => pinned.id === item.id && pinned.type === item.type
      );
      if (index !== -1) {
        state.pinnedItems.splice(index, 1);
      }
    },

    // Bookmarks
    setBookmarks(state, items) {
      state.bookmarks = items;
    },
    addToBookmarks(state, item) {
      state.bookmarks.push(item);
    },
    removeFromBookmarks(state, item) {
      const index = state.bookmarks.findIndex(
        (bookmark) => bookmark.id === item.id && bookmark.type === item.type
      );
      if (index !== -1) {
        state.bookmarks.splice(index, 1);
      }
    },

    // Events
    setEvents(state, events) {
      state.events = events;
    },
    addEvent(state, event) {
      state.events.push(event);
    },
    updateEvent(state, updatedEvent) {
      const index = state.events.findIndex(
        (event) => event.id === updatedEvent.id
      );
      if (index !== -1) {
        state.events.splice(index, 1, updatedEvent);
      }
    },
    removeEvent(state, eventId) {
      state.events = state.events.filter((event) => event.id !== eventId);
    },

    // Users
    setUsers(state, users) {
      state.users = users;
    },
    addUser(state, user) {
      state.users.push(user);
    },
    updateUser(state, updatedUser) {
      const index = state.users.findIndex((user) => user.id === updatedUser.id);
      if (index !== -1) {
        state.users[index] = updatedUser;
      }
    },
    removeUser(state, userId) {
      state.users = state.users.filter((user) => user.id !== userId);
    },

    // Unsorted
    user(state, user) {
      state.user = user;
    },
    showFrame(state, show) {
      state.hideFrame = !show;
    },
    currentItem(state, item) {
      state.currentItem = item;
      const index = state.recentItems.findIndex(
        (it) =>
          it.id == item.id &&
          (it.type.key == item.type.key || it.type == item.type)
      );
      if (index > -1) state.recentItems[index] = item;
      else state.recentItems.push(item);
    },
    setQuickActions(state, action) {
      state.quickAction = action;
    },
    clearCurrents(state) {
      state.currentItem = undefined;
      state.quickAction = {};
    },
    pinned(state, item) {
      const index = state.pinnedItems.findIndex(
        (it) => it.id == item.id && it.type == item.type
      );

      if (index < 0) state.pinnedItems.push(item);
      else if (index > -1) state.pinnedItems.splice(index, 1);
    },
  },

  actions: {
    //
    // Basics
    //

    // Importants, Fetch
    saveUser({ state, commit }, edited) {
      let user = {
        ...state.core.user.toSaveable(),
        ...edited,
        id: state.core.user.id,
      };
      miLog("Updating User", user);
      commit("setUser", user);
      firebase.saveUserData(user);
    },

    fetchUser({ commit, dispatch }, { found, onStop }) {
      miLog("Fetching User");
      commit("setLoading", MODES.LUSER);
      if (listener) listener();
      listener = firebase.listenToUser(
        async (user) => {
          if (listenerData) listenerData();
          if (listenerCompany) listenerCompany();

          miLog("Fetched User", user);
          commit("setUser", user);
          commit("setCompany", null);
          dispatch("fetchUserData", { user, found, onStop });

          if (!user) {
            if (listenerData) {
              listenerData();
            }
            if (listenerCompany) {
              listenerCompany();
              commit("setCompany", null);
            }
            onStop({ user: user });
            return;
          }

          return user;
        },
        (err) => {
          miLog(err);
          if (listenerCompany) {
            listenerCompany();
            commit("setCompany", null);
          }
          if (onStop) onStop({ error: "Error fetching User", err });
        }
      );
    },

    fetchUserData({ commit, dispatch }, { user, found, onStop }) {
      miLog("Fetching UserData from", user);
      commit("setLoading", MODES.LUDATA);
      if (listenerData) listenerData();
      if (!user) return;
      listenerData = firebase.listenToUserData(
        user.uid,
        (userData) => {
          miLog("Fetched UserData", userData);
          if (!userData) return onStop({ user: user, userData: userData });

          user = {
            ...user,
            ...userData,
          };
          commit("setUser", user);

          dispatch("fetchCompany", { user, userData, found, onStop });
          return userData;
        },
        (err) => {
          miLog(err);
          if (onStop) onStop({ error: "Error fetching UserData", err });
        }
      );
    },

    fetchCompany({ commit }, { user, userData, found, onStop }) {
      miLog("Fetching Company from", userData);
      commit("setLoading", MODES.LCOMPANY);
      if (listenerCompany) listenerCompany();

      if (!user.companyid) {
        onStop({ user: user, userData: userData });
        return;
      }

      listenerCompany = firebase.company.listenToCompany(
        user.companyid,
        (company) => {
          miLog("Fetched Company", company);
          commit("setCompany", company);
          if (!company)
            return onStop({
              user: user,
              userData: userData,
              company: company,
            });

          company = new Company(company);
          if (found) found(user, userData, company);
          return company;
        },
        (err) => {
          miLog(err);
          if (onStop) onStop({ error: "Error fetching Company", err });
        }
      );
    },

    // Context
    setCurrentItem({ commit }, item) {
      miLog("Setting current item", item);
      commit("setCurrentItem", item);
      commit("setContext", item);
    },
    resetContext({ commit }) {
      commit("resetContext");
    },
    deselectProject({ commit, state }) {
      if (state.context.key == "project") commit("resetContext");
    },

    //
    updateCurrentTime({ commit }) {
      const currentTime = Date.now();
      commit("setCurrentTime", currentTime);
    },
    setWindowSize({ commit }, { width, height }) {
      commit("setWindowSize", { width, height });
    },
    setLoading({ commit }, value) {
      commit("setLoading", value);
    },

    // Basic Funs
    // addItem({ commit }, item) {},

    //
    // Datas
    //

    // Counts
    async fetchCounts({ commit }) {
      const keys = Object.keys(this.state.counts);
      for (const key of keys) {
        const count = await firebase.getCountsFor(key);
        commit("SET_COUNT", { key, count });
      }
    },

    // Recent Items
    fetchRecent({ commit }) {
      firebase.getRecent().then((recent) => {
        commit("setRecent", recent);
      });
    },
    setRecentItems({ commit }, items) {
      commit("setRecentItems", items);
    },
    addRecentItem({ state, commit, dispatch }, item) {
      const exists = state.recentItems.some(
        (recent) => recent.id === item.id && recent.type === item.type
      );
      if (!exists) {
        commit("addToRecentItems", item);
        dispatch("saveRecentItems");
      }
    },
    removeRecentItem({ commit, dispatch }, item) {
      commit("removeFromRecentItems", item);
      dispatch("saveRecentItems");
    },

    // Pinned Items
    fetchPinned({ commit }) {
      firebase.getPinned().then((pinned) => {
        commit("setPinned", pinned);
      });
    },
    setPinnedItems({ commit }, items) {
      commit("setPinnedItems", items);
    },
    addPinnedItem({ state, commit, dispatch }, item) {
      const exists = state.pinnedItems.some(
        (pinned) => pinned.id === item.id && pinned.type === item.type
      );
      if (!exists) {
        commit("addToPinnedItems", item);
        dispatch("savePinnedItems");
      }
    },
    removePinnedItem({ commit, dispatch }, item) {
      commit("removeFromPinnedItems", item);
      dispatch("savePinnedItems");
    },

    // Bookmarks
    fetchBookmarks({ commit }) {
      firebase.getBookmarks().then((bookm) => {
        miLog("Fetched Bookmarks", bookm);
        commit("setBookmarks", bookm);
      });
    },

    setBookmarks({ commit }, items) {
      commit("setBookmarks", items);
    },

    addBookmark({ state, commit, dispatch }, item) {
      const exists = state.bookmarks.some(
        (bookmark) => bookmark.id === item.id && bookmark.type === item.type
      );
      if (!exists) {
        commit("addToBookmarks", item);
        dispatch("saveBookmarks");
      }
    },

    removeBookmark({ commit, dispatch }, item) {
      commit("removeFromBookmarks", item);
      dispatch("saveBookmarks");
    },

    // Events
    fetchEvents({ commit }) {
      firebase.listenTo(
        "events",
        (events) => {
          commit(
            "setEvents",
            events.map((it) => new MiEvent(it))
          );
        },
        [where("end", ">=", Date.now()), limit(10)]
      );
    },
    setEvents({ commit }, events) {
      commit("setEvents", events);
    },
    addEvent({ commit }, event) {
      commit("addEvent", event);
    },
    updateEvent({ commit }, updatedEvent) {
      commit("updateEvent", updatedEvent);
    },
    removeEvent({ commit }, eventId) {
      commit("removeEvent", eventId);
    },

    // Users
    fetchUsers({ commit }) {
      firebase.listenTo("users", (users) => {
        commit(
          "setUsers",
          users.map((it) => new MiUser(it))
        );
      });
    },
    addUser({ commit }, newUser) {
      // Perform API request or other async operation to add a new user
      // Once the user is added, commit the mutation to update the state
      commit("addUser", newUser);
    },
    updateUser({ commit }, updatedUser) {
      // Perform API request or other async operation to update the user
      // Once the user is updated, commit the mutation to update the state
      commit("updateUser", updatedUser);
    },
    removeUser({ commit }, userId) {
      // Perform API request or other async operation to remove the user
      // Once the user is removed, commit the mutation to update the state
      commit("removeUser", userId);
    },
  },

  getters: {
    //
    // Basics
    //
    getCurrentTimeFormatted: (state) => {
      const currentTime = state.currentTime;
      const options = {
        timeStyle: "medium",
      };
      return new Date(currentTime).toLocaleTimeString(undefined, options);
    },

    // Setup
    getSetupState: (state) => {
      return state.mode;
    },
    getSetupString: (state) => {
      let mode = -1;
      if (!state.core.user) mode = 1;
      if (!state.core.userData) mode = 2;
      if (!state.core.company) mode = 3;
      return MODES[mode];
    },
    getUser: (state) => state.core.user,
    getCompany: (state) => state.core.company,
    setupCompleted: (state) =>
      state.core.user && state.core.userData && state.core.company,

    // Context
    getContext: (state) => (key) => key ? state.context[key] : state.context,
    hasContext: (state) => state.context.hasContext,
    hasContextFor: (state) => (key) => state.context.hasContextFor(key),
    emptyContext: (state) => (key) => state.context.emptyContext(key),

    //
    // Datas
    //
    getCurrentItem: (state) => state.currentItem,
    getRecentItems: (state) => state.recentItems,
    getPinnedItems: (state) => state.pinnedItems,
    getBookmarks: (state) => state.bookmarks,
    getCounts: (state) => (type) => state.counts[type],
    getLoadingString: (state) => state.loading,

    // Events
    currentEvent(state) {
      const now = Date.now();
      return state.events.find(
        (event) => event.start <= now && event.end > now
      );
    },
    nextEvent: (state) => {
      const now = new Date();
      const sortedEvents = [...state.events].sort((a, b) => a.start - b.start);
      const upcomingEvents = sortedEvents.filter((event) => event.start > now);
      return upcomingEvents.length > 0 ? upcomingEvents[0] : null;
    },
    nextEvents: (state) => {
      const now = new Date();
      const sortedEvents = [...state.events].sort((a, b) => a.start - b.start);
      const upcomingEvents = sortedEvents.filter((event) => event.start > now);
      const nextTenEvents = upcomingEvents.slice(0, 10);
      return nextTenEvents;
    },
    getEventById: (state) => (eventId) => {
      return state.events.find((event) => event.id === eventId);
    },

    // Unsorted
    smallState(state) {
      return state?.window?.width < 1000 ?? false;
    },
    xsmallState(state) {
      return state?.window?.width < 450 ?? false;
    },

    isAdmin(state) {
      if (state.company.admins) {
        return (
          state.company.admins.find((it) => it == state.user.uid) != undefined
        );
      }
      return false;
    },

    thirdWindow(state) {
      if (state.window.width < state.window.height)
        return state.window.width / 3;

      return state.window.height / 3;
    },

    unfinishedProjectCount(state) {
      if (!state.company || !state.company.counts) return 0;
      if (!state.core.company.counts.projects) return 0;
      if (!state.core.company.counts.fprojects)
        return state.core.company.counts.projects;
      return (
        state.core.company.counts.projects - state.core.company.counts.fprojects
      );
    },

    userNameOrMail(state) {
      return state.user.displayName ? state.user.displayName : state.user.email;
    },

    userObj(state) {
      return {
        id: state.user.uid,
        title: state.user.title,
      };
    },
  },
});

export default vuex;
