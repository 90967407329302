const dashboard = {
  namespaced: true,
  name: "dash",
  state: {
    isDashboard: false,
    widgets: [],
  },
  getters: {
    isDashboard: (state) => state.isDashboard,
    widgets: (state) => state.widgets,
  },
  mutations: {
    SET_IS_DASHBOARD(state, payload) {
      state.isDashboard = payload;
    },
    SET_WIDGETS(state, payload) {
      state.widgets = payload;
    },
  },
  actions: {
    updateIsDashboard({ commit }, payload) {
      commit("SET_IS_DASHBOARD", payload);
    },
    updateWidgets({ commit }, payload) {
      commit("SET_WIDGETS", payload);
    },
  },
};

export default dashboard;
