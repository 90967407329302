export class FetchClass {
  type;
  arrayName;
  converterClass;
  extras = [];
  onFound;

  constructor(type, arrayName, converterClass, extras, onFound) {
    this.type = type;
    this.arrayName = arrayName;
    this.converterClass = converterClass;
    if (extras) this.extras = extras;
    this.onFound = onFound;
  }
}

export function mergeArray(localArray, incomingArray) {
  const mergedArray = [];
  const conflicts = [];

  // Create a map of local items for quick access
  const localMap = localArray.reduce((map, item) => {
    map[item.id] = item;
    return map;
  }, {});

  for (const incomingItem of incomingArray) {
    const localItem = localMap[incomingItem.id];
    if (!localItem) {
      // If local item doesn't exist, add the incoming item as it is
      mergedArray.push(incomingItem);
    } else {
      // Compare the keys and values of local and incoming items
      const diffItem = {};
      const mergedItem = {};

      for (const key in incomingItem) {
        if (localItem[key] !== incomingItem[key]) {
          if (!localItem[key]) {
            mergedItem[key] = incomingItem[key];
          } else if (!incomingItem[key]) {
            mergedItem[key] = localItem[key];
          } else
            diffItem[key] = {
              id: localItem ? localItem.id : incomingItem.id,
              local: localItem[key],
              remote: incomingItem[key],
            };
        }
      }
      if (Object.keys(diffItem).length > 0) {
        // If there are differences, add the diffItem to the conflicts
        conflicts.push({ id: localItem.id, ...diffItem });
      } else {
        // If no differences, add the local item
        mergedArray.push(localItem, ...mergedItem);
      }
    }
  }

  return { mergedArray, conflicts };
}
