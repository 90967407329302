import { cloneDeep } from "lodash";
import { miLog } from "../../main";
import Struct from "./datas/note.json";
import { Type } from "../Types";

export default class Note {
  constructor(note) {
    miLog("Creating Note...", note);
    if (Struct) {
      Object.assign(this, Struct);
    }
    if (note) Object.assign(this, note);
    this.type = new Type("note");
    miLog("Created Note", this);
  }

  toSaveable() {
    const itemToSave = { ...cloneDeep(this) };
    delete itemToSave.type;
    return itemToSave;
  }
}
