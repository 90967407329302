<template>
  <v-expand-x-transition>
    <v-hover
      :disabled="disableHover"
      :open-delay="openDelay"
      v-show="show"
      v-model="hover"
    >
      <con-button v-bind="$props" @click="$emit('click')">
        <template #icon>
          <slot name="icon">
            <v-icon outlined class="icon">
              <!-- gap && -->
              {{ hoverIcon && showHover ? hoverIcon : icon || "" }}
            </v-icon>
          </slot>
        </template>
        <template #text>
          <slot name="text">
            <v-expand-x-transition>
              <span v-show="noHoverText && !showHover">
                {{ noHoverText || "" }}
              </span>
            </v-expand-x-transition>
            <v-expand-x-transition>
              <span v-show="showHover && !disableHover">
                {{ title || "" }}
              </span>
            </v-expand-x-transition>
          </slot>
        </template>
      </con-button>
    </v-hover>
  </v-expand-x-transition>
</template>
<script>
import ConButton from "./ConButton.vue";
export default {
  components: { ConButton },
  mixins: [ConButton],
  props: {
    disabled: Boolean,
    expanded: Boolean,
    show: {
      type: Boolean,
      default: true,
    },
    elevation: {
      type: Number,
      default: 0,
    },
    openDelay: {
      type: Number,
      default: 200,
    },

    href: String,
    target: String,
    gap: {
      type: String,
      default: "1rem",
    },
    noHoverText: String,
    disableHover: Boolean,
    item: Object,
    icon: String,
    dark: Boolean,
    title: String,
    height: String,
    color: String,
    textColor: String,
    backgroundColor: String,
    hoverIcon: String,
    xLarge: Boolean,
    large: Boolean,
    small: Boolean,
    xSmall: Boolean,
    outlined: Boolean,
  },
  data() {
    return {
      hover: false,
    };
  },
  computed: {
    showHover() {
      return this.expanded || this.hover;
    },
    getHeight() {
      if (this.xLarge) return 64;
      if (this.large) return 56;
      if (this.medium) return 48;
      if (this.small) return 40;
      if (this.xSmall) return 32;
      return 48;
    },
  },
  watch: {
    hover(value) {
      this.$emit("hover", value);
    },

    disableHover() {
      this.hover = false;
    },
  },
};
</script>
