<template>
  <div class="widget vertical grow">
    <div class="router-actions">
      <component :is="getComponent('actions')" :small="true"></component>
    </div>

    <div class="scroll-white">
      <component :is="getComponent('default')" :small="true"></component>
    </div>

    <div class="router-bottom">
      <component :is="getComponent('bottom')" :small="true"></component>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import routes from "../../router/routes";

export default {
  props: ["widget"],
  computed: {
    ...mapGetters({
      isDashboard: "dashboard/isDashboard",
      widgets: "dashboard/widgets",
    }),
  },
  methods: {
    getComponent(name) {
      const route = routes.find((route) => route.name === this.widget.name);
      console.log(route);
      return route ? route.components[name] : null;
    },
  },
};
</script>

<style lang="scss" scoped></style>
