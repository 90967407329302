import { where } from "firebase/firestore";
import { cloneDeep } from "lodash";
import firebase from "../../db/firebase/MFirebase";
import Struct from "./datas/user.json";
import { Type } from "../Types";
import Location from "../location/location";

let listener;

export const userDefaults = {
  company: (id) => {
    return { company: id };
  },
};

export default class MiUser {
  constructor(user) {
    Object.assign(this, cloneDeep(Struct));
    if (user instanceof MiUser) {
      Object.assign(this, user);
      return;
    }

    if (user) {
      Object.keys(Struct).forEach((it) => {
        if (user[it]) this[it] = user[it];
      });

      if (user.uid) {
        this.id = user.uid;
        let splitted = user.displayName?.split(" ") || [];
        if (splitted.length > 0) {
          this.prename = splitted[0];
          if (splitted[0].length > 0) this.title += splitted[0][0];
        }
        if (splitted.length > 1) {
          this.surname = splitted[1];
          if (splitted[1].length > 0) this.title += splitted[1][0];
        }
        if (user.email) this.contact.mail = user.email;
        if (user.phoneNumber) this.contact.phone = user.phoneNumber;
        if (user.photoURL) this.imgURL = user.photoURL;
      }
    }

    this.location = new Location(this.location);
    this.type = new Type("user");
  }

  async listenToChanges() {
    if (!this.id) throw "No Id for user data";
    if (listener) listener();
    let flistener = firebase.listenToUserData(this.id, (found) => {
      Object.assign(this, found);
    });
    listener = flistener;
  }

  name() {
    let string = this.prename + " " + this.surname;
    if (!string.trim()) return undefined;
    return string;
  }

  toSmall() {
    return {
      id: this.id,
      title: this.title,
    };
  }

  complete() {
    return this.id && (this.prename || this.surname);
  }

  async findInDB() {
    const found = await firebase.getItemBy("users", [
      where("uid", "==", this.uid),
    ]);
    if (!found) return undefined;
    Object.assign(this, found);
    return this;
  }

  async addToDB() {
    let item = this.toSaveable();
    return await firebase.addItem("users", item);
  }

  // DB
  toSaveable() {
    let item = { ...cloneDeep(this) };
    delete item.type;
    item.location = {
      ...item.location,
    };
    delete item.listener;
    return item;
  }

  async add(onAdd) {
    let item = { ...this.toSaveable() };
    item = await firebase.addItem(this.type.key, item, onAdd);
    this.id = item.id;
    this.created = item.created;
  }

  async save() {
    let item = { ...this.toSaveable() };
    await firebase.setItem(this.type.key, item);
  }
  delete() {}
}
