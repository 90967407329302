import { cloneDeep } from "lodash";
import firebase from "../../db/firebase/MFirebase";
import { Type } from "../Types";
import MiUser from "../user/MiUser";
import Struct from "./datas/event.json";

const eventType = {
  title: "Standard",
  color: "black",
};

export default class MiEvent {
  constructor(event, context) {
    this.eventType = eventType;
    if (Struct) {
      Object.assign(this, Struct);
    }
    this.start = new Date().getTime();
    this.end = this.start + 3600000;

    if (context) {
      if (context.pID) this.pID = context.pID;
      if (context.cID) this.cID = context.cID;
    }

    if (event) {
      Object.assign(this, event);
      this.users = this.users.map((it) => new MiUser(it));
    }

    this.type = new Type("event");
    this.context = context;
  }

  toSaveable() {
    let item = { ...cloneDeep(this) };
    delete item.type;
    delete item.context;
    if (item.users)
      item.users = item.users.map((it) => {
        if (it.toSaveable) return it.toSaveable();
        return it;
      });
    return item;
  }

  async add(onAdd) {
    let item = { ...this.toSaveable() };
    item = await firebase.addItem(this.type.key, item, onAdd);
    this.id = item.id;
    this.created = item.created;
    return item;
  }

  async save() {
    if (!this.id) {
      return await this.add();
    }
    let item = { ...this.toSaveable() };
    item = await firebase.setItem(this.type.key, item);
    this.updated = item.updated;
    return item;
  }
}
