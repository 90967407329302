import { cloneDeep } from "lodash";
import firebase from "../../db/firebase/MFirebase";
import { Type } from "../Types";
import Struct from "./datas/supplier.json";
import Contact from "../contact/contact";
import Location from "../location/location";

export default class Supplier {
  constructor(supplier) {
    if (Struct) {
      Object.assign(this, Struct);
    }
    if (supplier) {
      Object.assign(this, supplier);
      if (supplier instanceof Supplier) return;

      if (!supplier.contacts) supplier.contacts = [];
      this.contacts = supplier.contacts.map((it) => {
        return new Contact(it);
      });
      if (!supplier.locations) supplier.locations = [];
      this.locations = supplier.locations.map((it) => {
        return new Location(it);
      });
    }

    this.type = new Type("supplier");
  }

  // DB
  toSaveable() {
    let item = { ...cloneDeep(this) };
    item.locations = item.locations.map((it) => {
      if (it.toSaveable) return it.toSaveable();
      return it;
    });
    item.contacts = item.contacts.map((it) => {
      if (it.toSaveable) return it.toSaveable();
      return it;
    });
    delete item.type;
    return item;
  }

  async add(onAdd) {
    let item = { ...this.toSaveable() };
    item = await firebase.addItem(this.type.key, item, onAdd);
    this.id = item.id;
    this.created = item.created;
  }

  async save() {
    let item = { ...this.toSaveable() };
    await firebase.setItem(this.type.key, item);
  }
  delete() {}
}
