// en.js

const en = {
  welcome: "Welcome",
  login: "Log in",
  go: "Go",
  short: "Short",
  edit: "Edit",
  newAccount: "New Account",
  basics: "General",
  emptyEntry: "No entries",
  loading: "Loading",
  category: "Category",
  color: "Color",
  recoms: "Recommendation",
  due: "Due",
  ok: "Ok",
  general: "General",
  title: "Title",
  text: "Text",
  desc: "Description",
  created: "Created",
  updated: "Updated",
  saving: "Saving",
  takeAndGo: "Take and Go",
  website: "Website",
  extras: "Extras",
  NA: "N/A",
  options: "Options",
  name: "Name",
  addcompany: "Add company",
  selectCompanyToStart: "Select a company to start",
  enterToCreate: "Press 'Enter' to create.",
  onlySearch: "Only for search",
  new: "New",
  more: "More",
  less: "Less",
  create: "Create",
  select: "Select",
  cancel: "Cancel",
  close: "Close",
  add: "Add",
  save: "Save",
  delete: "Delete",
  deleteSure: "Are you sure you want to delete",
  deleteEntry: "Delete entry",
  email: {
    base: "E-Mail",
    enter: "Enter email",
  },
  phone: {
    base: "Phone",
    enter: "Enter number",
  },
  password: {
    base: "Password",
    enter: "Enter password",
  },
  list: {
    empty: "No entries",
  },
  last: {
    title: "Last",
    updated: "Last updated",
  },
  countsHave: "{count} available",
  request: {
    title: "Request",
    create: "Create request",
  },
  project: {
    title: "Project",
    titles: "Projects",
    infos: "Project Information",
    name: "Project Name",
    none: "No project",
  },
  customer: {
    title: "Customer",
    titles: "Customers",
    short: "Short name",
    noSelected: "No customer selected",
    none: "No customer",
  },
  supplier: {
    title: "Supplier",
    titles: "Suppliers",
    short: "Short name",
    noSelected: "No supplier selected",
    none: "No supplier",
  },
  basedata: {
    title: "Item",
    titles: "Items",
    add: "Add item",
    noSelected: "No item selected",
    sku: "Item number",
    tracking: "Tracking",
    sizes: "Sizes",
    quality: "Quality?",
  },
  contact: {
    title: "Contact",
    titles: "Contacts",
    type: "Contact type",
    short: "Short name",
    add: "Add contact",
    noSelected: "No contact selected",
    m: "Mr.",
    w: "Mrs.",
  },
  user: {
    title: "User",
    titles: "Users",
    invite: "Invite user",
    short: "Short name",
    prename: "First name",
    surname: "Last name",
    noSelected: "No user selected",
  },
  location: {
    title: "Location",
    titles: "Locations",
    short: "Short name",
    add: "Add location",
    street: "Street",
    nr: "Number",
    zip: "ZIP",
    city: "City",
    noSelected: "No location selected",
  },
  tag: {
    title: "Tag",
    titles: "Tags",
    add: "Add tag",
  },
  note: {
    title: "Note",
    titles: "Notes",
  },
  todo: {
    title: "To-Do",
    titles: "To-Do's",
  },
  event: {
    title: "Event",
    titles: "Events",
  },
  doc: {
    title: "Document",
    titles: "Documents",
  },
  manu: {
    base: "Manufacturer",
    sku: "Mfr Item Number",
    web: "Mfr Website",
    desc: "Mfr Description",
  },
  tracking: {
    base: "Tracking",
    none: "No tracking",
    SN: "Serial Number",
    CH: "Lot Code",
    CHPre: "Lot Code Prefix",
  },
  sizes: {
    base: "Sizes",
    l: "Length",
    b: "Width",
    h: "Height",
    weight: "Weight",
    var: {
      m: "Meters",
      cm: "Centimeters",
      mm: "Millimeters",
      kg: "Kilograms",
      g: "Grams",
      mg: "Milligrams",
    },
  },
  company: {
    title: "Company",
    multi: "Companies",
    add: "Add company",
    new: "New company",
    edit: "Edit company",
  },
  error: {
    email: {
      invalid: "Invalid email address.",
    },
    userDisabled: "User account is disabled.",
    userNotFound: "User not found.",
    incorrectPassword: "Incorrect password.",
    passwordRequired: "Password is required.",
    generic: "An error occurred. Please try again later.",
  },
};

export default en;
