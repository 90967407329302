import PosStruct from "./datas/docPos.json";
import { miLog } from "../../main";
import { cloneDeep } from "lodash";

// const docTypeArray = ["AN", "AB", "LI", "RE"];

export default class DocPos {
  constructor(item) {
    miLog("Creating DocPos");
    if (item instanceof DocPos) {
      Object.assign(this, item);
    } else {
      for (let key in PosStruct) {
        if (item[key] != undefined) this[key] = item[key];
        else this[key] = PosStruct[key];
      }
    }
    miLog("Created DocPos", this);
  }

  toSaveable() {
    let itemToSave = { ...cloneDeep(this) };
    return itemToSave;
  }
}
