import firebase from "../../db/firebase/MFirebase";
import { miLog } from "../../main";
import { Type } from "../../types/Types";

const convertItem = (item) => {
  return {
    id: item?.id,
    title: item?.title,
    type: item?.type?.key,
  };
};

export default {
  name: "favorites",
  namespaced: true,

  state: {
    currentItem: null,
    lasts: {},

    // [convertItems, ...]
    bookmarks: [],

    // [convertItems, ...]
    recents: [],
    recentLimit: 10,

    // [convertItems, ...]
    pinned: [],

    // Full items
    // For Firebase to cache
    entries: {
      bookmarks: [],
      recents: [],
      pinned: [],
    },
  },

  mutations: {
    setCurrentItem(state, item) {
      state.currentItem = item;
    },

    resetEntries(state) {
      state.entries = {
        bookmarks: [],
        recents: [],
        pinned: [],
      };
    },
    setRecents(state, recents) {
      miLog("Recents", recents);
      if (recents instanceof Array) state.recents = recents;
      else throw "recents not an Array";
    },
    setBookmarks(state, bookmarks) {
      if (bookmarks instanceof Array) state.bookmarks = bookmarks;
      else throw "bookmarks not an Array";
    },
    setPinned(state, pinned) {
      if (pinned instanceof Array) state.pinned = pinned;
      else throw "pinned not an Array";
    },

    ADD_RECENT(state, item) {
      const existingIndex = state.recents.findIndex(
        (existingItem) =>
          existingItem.type == item.type && existingItem.id == item.id
      );
      miLog("ADD_RECENT", item, existingIndex);
      if (existingIndex !== -1) {
        state.recents.splice(existingIndex, 1);
      }
      state.recents.unshift(item);
      state.lasts[item.type] = item;

      // Limit the length of the recents array to recentLimit
      if (state.recents.length > state.recentLimit) {
        state.recents.length = state.recentLimit;
      }
    },

    ADD_BOOKMARK(state, bookmark) {
      const existingIndex = state.bookmarks.findIndex(
        (item) => item.type == bookmark.type && item.id == bookmark.id
      );
      if (existingIndex !== -1) {
        state.bookmarks.splice(existingIndex, 1);
      }
      state.bookmarks.unshift(bookmark);
    },

    ADD_PINNED(state, item) {
      const existingIndex = state.pinned.findIndex(
        (existingItem) =>
          existingItem.type == item.type && existingItem.id == item.id
      );
      if (existingIndex !== -1) {
        state.pinned.splice(existingIndex, 1);
      }
      state.pinned.unshift(item);
    },

    REMOVE_BOOKMARK(state, bookmarkId) {
      state.bookmarks = state.bookmarks.filter(
        (item) => item.id !== bookmarkId
      );
    },
    REMOVE_RECENT(state, itemId) {
      state.recents = state.recents.filter((item) => item.id !== itemId);
    },
    REMOVE_PINNED(state, itemId) {
      state.pinned = state.pinned.filter((item) => item.id !== itemId);
    },

    /**
     * Add a recent item to the recents array mutation.
     *
     * @param {Object} state - Vuex state object.
     * @param {Object} item - Recent item to be added.
     */
    addRecentItem(state, item) {
      state.entries.recents.push(item);
    },

    /**
     * Add a bookmark item to the bookmarks array mutation.
     *
     * @param {Object} state - Vuex state object.
     * @param {Object} item - Bookmark item to be added.
     */
    addBookmarkItem(state, item) {
      state.entries.bookmarks.push(item);
    },

    /**
     * Add a pinned item to the pinned array mutation.
     *
     * @param {Object} state - Vuex state object.
     * @param {Object} item - Pinned item to be added.
     */
    addPinnedItem(state, item) {
      state.entries.pinned.push(item);
    },
  },

  actions: {
    setFavorites({ commit, dispatch }, favorites) {
      let { recents, bookmarks, pinned } = favorites;
      commit("setRecents", recents || []);
      commit("setBookmarks", bookmarks || []);
      commit("setPinned", pinned || []);
      dispatch("fetchEntries");
    },

    fetchEntries({ state, commit }) {
      commit("resetEntries");
      if (state.recents) {
        state.recents.forEach(async (item) => {
          try {
            miLog("Getting recents", item);
            const snapshot = await firebase[item.type].get(item.id);
            commit("addRecentItem", snapshot);
          } catch (error) {
            console.error("Error fetching recent item:", error);
          }
        });
      }

      if (state.bookmarks) {
        state.bookmarks.forEach(async (item) => {
          try {
            const snapshot = await firebase[item.type].get(item.id);
            commit("addBookmarkItem", snapshot);
          } catch (error) {
            console.error("Error fetching bookmark item:", error);
          }
        });
      }

      if (state.pinned) {
        state.pinned.forEach(async (item) => {
          try {
            const snapshot = await firebase[item.type].get(item.id);
            commit("addPinnedItem", snapshot);
          } catch (error) {
            console.error("Error fetching pinned item:", error);
          }
        });
      }
    },

    setCurrentItem({ commit, dispatch }, item) {
      commit("setCurrentItem", item);
      if (item) dispatch("addRecentItem", item);
    },

    // Bookmarks
    addBookmark({ commit, state, dispatch }, item) {
      const itemToSave = convertItem(item);
      if (!itemToSave) return;
      commit("ADD_BOOKMARK", itemToSave);
      const bookmarks = state.bookmarks;
      miLog("Adding Bookmarks", item, state.bookmarks);
      dispatch("company/updateCompanyBookmarks", bookmarks, { root: true });
    },

    removeBookmark({ commit, state, dispatch }, bookmarkId) {
      commit("REMOVE_BOOKMARK", bookmarkId);
      const bookmarks = state.bookmarks;
      dispatch("company/updateCompanyBookmarks", bookmarks, { root: true });
    },

    // Recents
    addRecentItem({ commit, state, dispatch }, item) {
      miLog("Add RECENT", item);
      const itemToSave = convertItem(item);
      if (!itemToSave) return;
      commit("ADD_RECENT", itemToSave);
      const recents = state.recents;
      dispatch("company/updateCompanyRecents", recents, { root: true });
    },

    removeRecentItem({ commit, state, dispatch }, itemId) {
      commit("REMOVE_RECENT", itemId);
      const recents = state.recents;
      dispatch("company/updateCompanyRecents", recents, { root: true });
    },

    // Pinned
    addPinned({ commit, state, dispatch }, item) {
      const itemToSave = convertItem(item);
      if (!itemToSave) return;
      commit("ADD_PINNED", itemToSave);
      const pinned = state.pinned;
      dispatch("company/updateCompanyPinned", pinned, { root: true });
    },

    removePinned({ commit, state, dispatch }, itemId) {
      commit("REMOVE_PINNED", itemId);
      const pinned = state.pinned;
      dispatch("company/updateCompanyPinned", pinned, { root: true });
    },
  },

  getters: {
    currentItem: (state) => state.currentItem,
    getLast: (state) => (key) => state.lasts[key],
    getBookmarks: (state) =>
      state.bookmarks.map((it) => {
        const type = new Type(it.type);
        let updatedItem = { ...it, type };
        try {
          if (type.class) updatedItem = new type.class(updatedItem);
        } catch (e) {
          miLog(e);
        }
        return updatedItem;
      }),

    getRecentItems: (state) =>
      state.recents.map((it) => {
        const type = new Type(it.type);
        let updatedItem = { ...it, type };
        try {
          if (type.class) updatedItem = new type.class(updatedItem);
        } catch (e) {
          miLog(e);
        }
        return updatedItem;
      }),

    getPinnedItems: (state) =>
      state.pinned.map((it) => {
        const type = new Type(it.type);
        let updatedItem = { ...it, type };
        try {
          if (type.class) updatedItem = new type.class(updatedItem);
        } catch (e) {
          miLog(e);
        }
        return updatedItem;
      }),

    isBookmarked: (state) => (itemId) => {
      return state.bookmarks.some((item) => item.id === itemId);
    },

    isPinned: (state) => (itemId) => {
      return state.pinned.some((item) => item.id === itemId);
    },
  },
};
