<template>
  <v-app>
    <v-main class="main">
      <div class="cont vertical centered gapped">
        <h1>MiSolar</h1>
        <h4>Lade...</h4>

        <v-progress-linear
          height="20px"
          :indeterminate="true"
        ></v-progress-linear>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "LoadingPage",

  data() {
    return {};
  },

  computed: {
    ...mapGetters("miuser", ["uLoaded"]),
    ...mapGetters("company", ["cLoaded"]),
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
.main {
  background-color: var(--v-primary-darken2);

  .cont {
    width: 100%;
    height: 100%;
    padding-bottom: 20vh;
  }
}
</style>
