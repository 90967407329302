import { cloneDeep } from "lodash";
import firebase from "../../db/firebase/MFirebase";
import MiUser from "../../types/user/MiUser";
import { miLog } from "../../main";

const initState = {
  uID: null,
  worker: null,
  workers: [],
  loaded: {},
};

let listener;

export default {
  namespaced: true,
  name: "worker",

  state: {
    ...cloneDeep(initState),
  },

  mutations: {
    setWorkerID(state, id) {
      state.sID = id;
    },

    setWorker(state, worker) {
      state.sID = worker.id;
      state.worker = worker;
    },

    setWorkers(state, workers) {
      state.workers = workers;
    },

    setLoaded(state, { key, value }) {
      state.loaded[key] = value;
    },

    resetState(state) {
      Object.assign(state, initState);
    },
  },

  actions: {
    async setWorkerID({ commit, dispatch }, sID) {
      const miWorker = new MiUser(await firebase.worker.getWorker(sID));
      commit("setWorkerID", sID);
      dispatch("setWorker", miWorker);
      return miWorker;
    },

    async setWorker({ commit }, worker) {
      if (!worker) {
        commit("resetState");
        return;
      }

      if (!(worker instanceof MiUser)) {
        worker = new MiUser(worker);
      }

      commit("setWorker", worker);
      return worker;
    },

    async saveWorker(_, worker) {
      if (!(worker instanceof MiUser)) throw "Worker not MiUser";
      firebase.worker.saveWorker(worker);
    },

    async listenToAllWorkers({ commit }) {
      commit("setLoaded", { key: "user", value: false });
      if (listener) listener();
      firebase.listenTo(
        "user",
        (users) => {
          commit(
            "setWorkers",
            users.map((it) => new MiUser(it))
          );
          commit("setLoaded", { key: "user", value: true });
        },
        null,
        (err) => {
          miLog(err);
          commit("setLoaded", { key: "user", value: true });
        }
      );
    },
  },

  getters: {
    getWorker: (state) => state.worker,
    getWorkers: (state) => state.workers,
  },
};
