import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import icons from "./icons";

Vue.use(Vuetify);

export const defaultTheme = {
  onBack: "white",
  // Bgs etc
  primary: "#77003B",
  // Infos- Chips etc
  accent: "#D70072",

  // Navigation
  // Buttons & AppFrame
  nav: "#D70072",

  // Context Actions
  // Buttons: clear, add -> Actions
  actions: "#D70072",

  // Add, Edit, Remove?
  info: "#375C79",
  awhite: "#FEFEFE33",

  error: "#FF5252",
  bg: "#333333",
};

export default new Vuetify({
  theme: {
    dark: true,
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        ...defaultTheme,
      },
      dark: {
        ...defaultTheme,
      },

      micom: {
        micom: "#77003B",

        primary: "#77003B",
        secondary: "#DD0307",
        accent: "#D70072",
        loading: "#D70072",

        navcolor: "#7C7A7A",

        error: "#FF5252",
        info: "#6196D4",
        success: "#4CAF50",
        warning: "#FFC107",
        background: "#F5F5F5",
      },
    },
  },

  icons: {
    iconfont: "mdi", // default
    values: {
      ...icons,
    },
  },
});
