import { miLog } from "../../main";
import Project from "../../types/project/project";
import firebase from "../../db/firebase/MFirebase";
import { orderBy, where } from "firebase/firestore";
import MiEvent from "../../types/event/mievent";
import Doc from "../../types/doc/doc";
import Note from "../../types/note/note";
import { Todo } from "../../types/todo/Todos";
import { FetchClass } from "../helper";
import { groupBy } from "lodash";
import Goal from "../../types/goal/goal";
import { formsConfig } from "../../types/form/form";

let openProjectListener;

const initialState = {
  pID: null,
  project: null,
  viewMode: 0,
  selectedIds: {},

  listeners: {},
  conflicts: {},

  flags: {
    dialogVisible: false,
    loading: false,
    freshLoad: false,

    filesLoaded: false,
    imagesLoaded: false,

    loaded: {},

    fetch: {
      events: false,
      images: false,
      files: false,
      docs: false,
      listen: [],
    },
  },

  counts: {
    todos: 0,
    events: 0,
  },

  openProjects: [],

  forms: [],
  docs: [],
  files: [],
  goals: [],
  todos: [],
  events: [],
  images: [],
  notes: [],
};

function removeExtension(filename) {
  return filename.substring(0, filename.lastIndexOf(".")) || filename;
}

let projListener;

export default {
  namespaced: true,
  name: "project",

  state: {
    ...initialState,
  },

  mutations: {
    setViewMode: (state, mode) => {
      state.viewMode = mode;
    },

    setProjectID: (state, pID) => {
      miLog("Setted pID", pID);
      state.pID = pID;
    },

    setProject: (state, project) => {
      miLog("Setted Project", project);
      state.project = project;
    },

    setOpenProjects(state, projects) {
      miLog("Setted openProjects", projects);
      state.openProjects = projects;
    },

    setArrayData(state, { arrayName, data }) {
      miLog("setArrayData", arrayName, data);
      if (!state.project) throw "No Project set";
      // const { mergedArray, conflicts } = mergeArray(state[arrayName], data);
      // miLog("mergedArray", mergedArray);
      state[arrayName] = data;
      // state.conflicts[arrayName] = conflicts;
    },

    setListener(state, { arrayName, listener }) {
      state.listeners[arrayName] = listener;
    },

    // Flags
    setFlag(state, { key, value }) {
      miLog("Set Flag", key, value);
      state.flags[key] = value;
    },

    setLoaded(state, key) {
      miLog("Set Loaded", key);
      state.flags.loaded[key] = true;
    },

    // Counts
    setCount(state, { key, count }) {
      miLog("Set Count", key, count);
      if (Object.prototype.hasOwnProperty.call(state.counts, key)) {
        state.counts[key] = count;
      }
    },

    setType: (state, type) => {
      state.project.type = type;
    },

    setTitle: (state, title) => {
      state.project.title = title;
    },

    setTags: (state, tags) => {
      state.project.tags = tags;
    },
    addTag: (state, tag) => {
      state.project.tags.push(tag);
    },
    removeTag: (state, tag) => {
      state.project.tags = state.project.tags.filter((t) => t !== tag);
    },

    setCustomer: (state, customer) => {
      state.project.customer = customer;
    },

    setLocation: (state, location) => {
      state.project.location = location;
    },

    setTodos: (state, todos) => {
      state.todos = todos;
    },

    setTodoGroups: (state, todoGroups) => {
      state.todoGroups = todoGroups;
    },

    setUsers: (state, users) => {
      state.project.users = users;
    },

    setEvents: (state, events) => {
      state.project.events = events;
    },

    setGoals: (state, goals) => {
      state.project.goals = goals;
    },

    setBasedatas: (state, basedatas) => {
      state.project.basedatas = basedatas;
    },

    setSerials: (state, serials) => {
      state.project.serials = serials;
    },

    setFrom: (state, from) => {
      state.project.from = from;
    },

    setTo: (state, to) => {
      state.project.to = to;
    },

    setNotes: (state, notes) => {
      miLog("Setting Notes", notes);
      state.project.notes = notes;
    },

    addNote: (state, note) => {
      miLog("Adding Note", note);
      state.notes.push(note);
    },

    removeNote: (state, noteId) => {
      miLog("Remove Note", noteId);
      const index = state.notes.findIndex((note) => note.id === noteId);
      if (index !== -1) {
        state.notes.splice(index, 1);
      }
    },

    setDocs: (state, docs) => {
      miLog("Set Docs", docs);
      state.docs = docs;
    },

    addDoc: (state, doc) => {
      miLog("Adding Doc", doc);
      state.docs.push(doc);
    },

    removeDoc: (state, docId) => {
      miLog("Remove Doc", docId);
      const index = state.docs.findIndex((doc) => doc.id === docId);
      if (index !== -1) {
        state.docs.splice(index, 1);
      }
    },

    setImages(state, images) {
      miLog("Set Images", images);
      state.images = images;
    },

    addImage(state, image) {
      miLog("Adding Image", image);
      state.images.push(image);
    },

    removeImage(state, path) {
      miLog("Remove Image", path);
      const index = state.images.findIndex((it) => it.path === path);
      if (index !== -1) {
        state.images.splice(index, 1);
      }
    },

    setFiles(state, files) {
      miLog("Set Files", files);
      state.files = files;
    },

    addFile(state, file) {
      miLog("Adding File", file);
      state.files.push(file);
    },

    removeFile(state, path) {
      miLog("Remove File", path);
      const index = state.files.findIndex((it) => it.path === path);
      if (index !== -1) {
        state.files.splice(index, 1);
      }
    },

    SET_FORMS(state, forms) {
      state.forms = forms;
    },

    ADD_FORM(state, form) {
      // Add the form to the forms array
      state.forms.push(form);

      // Create a simplified version for the project.forms
      const projectForm = {
        title: form.title,
        entries: form.entries.map((entry) => ({
          title: entry.title,
          value: entry.value,
        })),
      };

      // Add the simplified form to the project.forms array
      state.project.forms.push(projectForm);
    },

    UPDATE_FORM(state, { index, form }) {
      // Update the existing form
      state.forms[index] = form;

      // Map the updated forms to a new array containing only the title and value
      state.project.forms = state.forms.map((form) => ({
        title: form.title,
        entries: form.entries.map((entry) => ({
          title: entry.title,
          value: entry.value,
        })),
      }));
    },

    resetState: (state) => {
      Object.assign(state, initialState);
      miLog("ProjModule", "Resetted State", state);
    },
  },

  actions: {
    setViewMode({ commit }, mode) {
      commit("setViewMode", mode);
    },

    async setProjectID({ commit, dispatch }, pID) {
      commit("setProjectID", pID);
      if (projListener) projListener();
      projListener = firebase.listenToOne(
        "project",
        pID,
        (proj) => {
          dispatch("setProject", proj);
          return proj;
        },
        () => {
          dispatch("setProject", undefined);
        }
      );
    },

    async setProject({ state, commit, dispatch }, project) {
      if (!project) {
        commit("resetState");
        return;
      }
      if (!state.pID) {
        dispatch("setProjectID", project.id);
        return;
      }

      if (!(project instanceof Project)) {
        project = new Project(project);
      }

      dispatch("setForms", project.forms);
      commit("setProject", project);
      dispatch("fetch");
      return project;
    },

    setForms({ commit }, forms) {
      const combinedForms = formsConfig.map((configSection) => {
        const newSection = { ...configSection };
        // Find the matching section in forms by comparing titles
        const matchingSection = forms.find(
          (formSection) => formSection.title === configSection.title
        );

        newSection.entries = configSection.entries.map((entry) => {
          const newEntry = { ...entry, value: null }; // Start with null value
          // Find the matching entry in matchingSection by comparing titles
          const matchingEntry = matchingSection?.entries?.find(
            (formEntry) => formEntry.title === entry.title
          );
          if (matchingEntry) {
            newEntry.value = matchingEntry.value; // Set the value if a match is found
          }
          return newEntry;
        });

        return newSection;
      });

      commit("SET_FORMS", combinedForms);
    },

    saveForm({ commit, state }, form) {
      const existingFormIndex = state.forms.findIndex(
        (f) => f.title === form.title
      ); // Find the index of the existing form by title

      if (existingFormIndex === -1) {
        // If title is not found, it's a new form
        commit("ADD_FORM", form); // Commit the ADD_FORM mutation
      } else {
        // If title is found, it's an existing form
        commit("UPDATE_FORM", { form, index: existingFormIndex }); // Commit the UPDATE_FORM mutation
      }
    },

    setProjectLocation({ commit }, location) {
      commit("setLocation", location);
    },

    setTags({ commit }, tags) {
      commit("setTags", tags);
    },

    addTag({ commit, state }, tag) {
      if (!state.project.tags.includes(tag)) {
        commit("addTag", tag);
      }
    },

    removeTag({ commit }, tag) {
      commit("removeTag", tag);
    },

    // Fetch when Project Setted
    fetch({ dispatch, state }) {
      if (state.flags.fetch.events) dispatch("fetchEvents");
      if (state.flags.fetch.images) dispatch("fetchImages");
      if (state.flags.fetch.files) dispatch("fetchFiles");
      state.flags.fetch.listen.forEach((it) => {
        dispatch("listenToType", it);
      });
      state.flags.fetch.listen = [];
    },

    // Listeners
    async fetchDocs({ dispatch }) {
      const fetchObj = new FetchClass("docs", "docs", Doc);
      await dispatch("fetchItems", fetchObj);
    },

    async fetchEvents({ dispatch }) {
      const fetchObj = new FetchClass("events", "events", MiEvent, [
        orderBy("start", "asc"),
      ]);
      await dispatch("fetchItems", fetchObj);
    },

    async fetchGoals({ dispatch }) {
      const fetchObj = new FetchClass("todoGroups", "todoGroups", Goal);
      await dispatch("fetchItems", fetchObj);
    },

    async fetchTodos({ dispatch, state }) {
      state;
      Todo;
      groupBy;
      const fetchObj = new FetchClass("todos", "todos", Todo);
      dispatch("fetchItems", fetchObj);
    },

    async fetchNotes({ dispatch }) {
      const fetchObj = new FetchClass("notes", "notes", Note);
      dispatch("fetchItems", fetchObj);
    },

    // StorageFiles
    async fetchImages({ state, commit, dispatch }) {
      commit("setFlag", { key: "imagesLoaded", value: false });
      if (!state.project) {
        miLog("Set future fetch Images");
        state.flags.fetch.images = true;
        return;
      }
      await dispatch("fetchItemsFromStorage", "images");
      commit("setFlag", { key: "imagesLoaded", value: true });
      state.flags.fetch.images = false;
    },

    async fetchFiles({ state, commit, dispatch }) {
      if (!state.project) {
        miLog("Set future fetch Files");
        state.flags.fetch.files = true;
        return;
      }
      commit("setFlag", { key: "filesLoaded", value: false });
      await dispatch("fetchItemsFromStorage", "files");
      commit("setFlag", { key: "filesLoaded", value: true });
      state.flags.fetch.files = false;
    },

    // Functions
    async updateCounts({ commit, state }, id) {
      const countKeys = Object.keys(state.counts);
      for (const key of countKeys) {
        const count = await firebase.getCountsFor(key, [
          where("pID", "==", id),
        ]);
        commit("setCount", { key, count });
      }
    },

    getOpenProjects({ commit }, user) {
      miLog("Get OPEN PROJECTS", "User is Admin", user?.isAdmin);

      if (openProjectListener) openProjectListener();
      if (!user) throw "No user to get projects";

      let extras = [where("finished", "!=", true)];
      if (!user.isAdmin) {
        extras.push(where("users", "array-contains", user.id));
      }

      openProjectListener = firebase.project.listenAll(
        (found) => {
          commit("setOpenProjects", found);
        },
        [...extras],
        (err) => miLog(err)
      );
    },

    // General Functions
    setArray({ commit }, { key, item }) {
      commit(`set${key.charAt(0).toUpperCase()}${key.slice(1)}`, item);
    },

    async addItem({ commit, state }, { key, item }) {
      item.pID = state.project.id;
      miLog("ProjectModule", "AddItem", key, item);
      await firebase.addItem(key, item);
      commit;
      // commit(`add${key.charAt(0).toUpperCase()}${key.slice(1)}`, item);
    },

    removeItem({ commit }, { key, item }) {
      miLog("REMOVE", item);
      let id = typeof item === "object" ? item.id : item;
      commit(`remove${key.charAt(0).toUpperCase()}${key.slice(1)}`, id);
      firebase.deleteItem(key, id);
    },

    resetState({ commit, state }) {
      miLog("Reset State", state.listeners);
      Object.values(state.listeners).forEach((it) => it());
      if (openProjectListener) openProjectListener();
      commit("resetState");
    },

    // Helpers
    async fetchItems({ dispatch, state }, fetchObj) {
      if (!state.project) {
        miLog("Set future listenTo", fetchObj);
        state.flags.fetch.listen.push(fetchObj);
        return;
      }
      dispatch("listenToType", fetchObj);
    },

    async fetchItemsFromStorage({ commit, state }, key) {
      if (!state.project) {
        throw new Error("No Project to fetch items from");
      }
      if (!key) {
        throw new Error("Key missing for fetching Items");
      }

      miLog(`Fetching ${key} from storage`);

      const upperCaseKey = key.charAt(0).toUpperCase();
      const funKey = upperCaseKey + key.slice(1, -1);

      if (!state.project.storage) {
        state.project.storage = {};
      }
      if (!state.project.storage[key]) {
        state.project.storage[key] = [];
      }
      if (state.project.storage[key].length === 0) {
        commit(`set${upperCaseKey + key.slice(1)}`, []);
      }

      const newPaths = state.project.storage[key];
      const existingPaths = state[key].map((item) => item.path);

      const pathsToAdd = newPaths.filter(
        (path) => !existingPaths.includes(path)
      );
      const pathsToRemove = existingPaths.filter(
        (path) => !newPaths.includes(path)
      );

      try {
        // Remove items that are no longer in the project
        pathsToRemove.forEach((path) => {
          commit(`remove${funKey}`, path);
        });

        // Fetch and add new items
        for (let index = 0; index < pathsToAdd.length; index++) {
          let itemPath = pathsToAdd[index];
          itemPath = await firebase.storage.getURL(itemPath);
          commit(`add${funKey}`, {
            path: itemPath,
            name: removeExtension(itemPath.split("/").pop()),
            type: itemPath.split(".").pop(),
          });
        }

        commit("setLoaded", key);
      } catch (error) {
        miLog(error);
      }
    },

    listenToType(
      { commit, state },
      { type, arrayName, extras, converter, onFound }
    ) {
      if (state.listeners[arrayName]) state.listeners[arrayName]();

      miLog("Setting listener for ", type, extras);
      let listener = firebase.listenTo(
        type,
        (data) => {
          if (onFound) onFound(data);
          commit("setArrayData", { arrayName, data });
          commit("setLoaded", type);
        },
        [where("pID", "==", state.project.id), ...extras],
        (err) => miLog(err),
        converter
      );

      // Store the listener reference in the state
      commit("setListener", { arrayName, listener });
    },
  },

  getters: {
    getProject: (state) => state.project,
    getViewMode: (state) => state.viewMode,
    getType: (state) => state.project.type,
    getTitle: (state) => state.project.title,
    getTags: (state) => state.project.tags,
    getCustomer: (state) => state.project.customer,
    getContact: (state) => state.project.contact,
    getLocation: (state) => state.project.location,
    getTodos: (state) => state.todos,
    getTodosForGoal: (state) => (goalID) =>
      state.todos.filter((it) => it.gID == goalID),
    getUsers: (state) => state.project.users,
    getEvents: (state) => state.events,
    getNotes: (state) => state.notes,
    getGoals: (state) => state.project.goals,
    getForms: (state) => state.forms,
    getBasedatas: (state) => state.project.basedatas,
    getSerials: (state) => state.project.serials,
    getDocs: (state) => state.docs,
    getDocsAN: (state) => state.docs.filter((it) => it.docType == "AN"),
    getDocsAB: (state) => state.docs.filter((it) => it.docType == "AB"),
    getDocsLI: (state) => state.docs.filter((it) => it.docType == "LI"),
    getDocsRE: (state) => state.docs.filter((it) => it.docType == "RE"),
    getImages: (state) => state.images,
    getFiles: (state) => state.files,
    getFrom: (state) => state.project.from,
    getTo: (state) => state.project.to,
    getCreatedTime: (state) => state.project?.created?.time,
    getCreatedTitle: (state) => state.project?.created?.user?.title,
    getUpdatedTime: (state) => state.project?.updated?.time,
    getUpdatedTitle: (state) => state.project?.updated?.user?.title,

    //   Counts
    getTodoCount: (state) => state.counts.todos,
    getEventCount: (state) => state.counts.events,
    getDocCount: (state) => state.counts.docs,

    //   Filtered
    nextEvent: (state, getters) => {
      const current = new Date().getTime();
      const running = (event) => event.start <= current && event.end >= current;
      const notStarted = (event) => event.start >= current;
      return getters.getEvents.find(
        (event) => running(event) || notStarted(event)
      );
    },

    //   Flags
    isFreshLoad: (state) => state.flags.freshLoad,
    isLoading: (state) => state.flags.loading,
    imagesLoaded: (state) => state.flags.imagesLoaded,
    filesLoaded: (state) => state.flags.filesLoaded,
    openProjects: (state) => state.openProjects,
    getLoaded: (state) => (key) => state.flags.loaded[key],
  },
};
