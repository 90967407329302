import firebase, { miUser } from "../../db/firebase/MFirebase";
import i18n, { locales } from "../../lang/lang";

// langModule.js
export default {
  namespaced: true,
  name: "lang",

  state: {
    currentLocale: "de",
    locales: locales,
  },

  mutations: {
    SET_LOCALE(state, locale) {
      // Mutation to update the current locale
      if (state.locales.includes(locale)) {
        state.currentLocale = locale;
        i18n.locale = locale;
      }
    },
  },

  actions: {
    setLocale({ commit }, locale) {
      // Action to change the current locale
      commit("SET_LOCALE", locale);
      if (miUser)
        firebase.updateMiUser({
          lang: locale,
        });
    },
  },
  getters: {
    getCurrentLocale: (state) => state.currentLocale,
    getLocales: (state) => state.locales,
  },
};
