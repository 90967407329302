// devModule.js

import { where } from "firebase/firestore";
import firebase from "../../db/firebase/MFirebase";

const state = {
  isAdmin: false,
  requests: [],
  submittedVersion: null,
};

const mutations = {
  setRequests(state, requests) {
    state.requests = requests;
  },
  setSubmittedVersion(state, version) {
    state.submittedVersion = version;
  },
};

const actions = {
  async fetchRequests({ commit }) {
    try {
      // const requests = await firebase.getRequests([
      //   where("answered", "!=", true),
      // ]);
      // commit("setRequests", requests);
      commit;
      where;
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  async submitVersion({ commit }, version) {
    try {
      await firebase.submitVersion(version);
      commit("setSubmittedVersion", version);
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
};

const getters = {
  isAdmin: (state, getters, rootState, rootGetters) => {
    const { admins } = rootGetters["company/getCompany"];
    const { uid } = rootGetters["user/getUser"];
    return admins && admins.includes(uid);
  },
};

export default {
  namespaced: true,
  name: "dev",

  state,
  mutations,
  actions,
  getters,
};
