<template>
  <vuedraggable
    v-if="!smallState"
    class="draggables"
    v-bind="dragOptions"
    :list="currentSideList"
    group="items"
    @start="drag = true"
    @end="drag = false"
    disabled
  >
    <TransitionGroup
      class="list-cont vertical gapped half"
      type="transition"
      :name="!drag ? 'list' : null"
      @before-leave="beforeLeave"
    >
      <v-hover
        v-slot="{ hover }"
        v-for="item of currentSideList"
        :key="item.type.key + ' ' + item.id"
      >
        <div
          class="item horizontal gapped half centered-v"
          :class="isCurrent(item) ? 'current' : ''"
          color="white"
          @click="goTo(item)"
          style="cursor: pointer"
        >
          <v-icon color="white">
            {{ item.type.icon }}
          </v-icon>
          <span v-if="hover"> # {{ item.id }} </span>
          <span v-else> {{ item.title }} </span>
        </div>
      </v-hover>
    </TransitionGroup>
  </vuedraggable>
  <TransitionGroup
    v-else
    class="list-cont vertical gapped half"
    type="transition"
    :name="!drag ? 'list' : null"
    @before-leave="beforeLeave"
  >
    <v-hover
      v-slot="{ hover }"
      v-for="item of currentSideList"
      :key="item.type.key + ' ' + item.id"
    >
      <div
        class="item horizontal gapped half centered-v"
        :class="isCurrent(item) ? 'current' : ''"
        color="white"
        @click="goTo(item)"
        style="cursor: pointer"
      >
        <v-icon color="white">
          {{ item.type.icon }}
        </v-icon>
        <span class="item-title" v-if="hover">{{ item.id }}</span>
        <span class="item-title" v-else>{{ item.title }}</span>
      </div>
    </v-hover>
  </TransitionGroup>
</template>
<script>
import vuedraggable from "vuedraggable";
import { mapGetters } from "vuex";
export default {
  name: "SideBarList",
  components: {
    vuedraggable,
  },
  props: {
    currentSideList: {
      type: Array,
      required: true,
    },
    isCurrent: {
      type: Function,
      required: true,
    },
    goTo: {
      type: Function,
    },
  },

  data() {
    return {
      drag: false,
    };
  },

  computed: {
    ...mapGetters(["smallState"]),
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },
  methods: {
    beforeLeave() {
      // Implement the beforeLeave method if needed
    },
  },
};
</script>

<style lang="scss" scoped>
/* Add custom styles here */

.list-cont {
  position: relative;
  height: 100%;

  .item {
    border: 1px dashed grey;
    border-radius: 2rem;
    padding: 8px 4px 8px 12px;
    overflow: hidden;
    text-overflow: ellipsis;

    transition: all 2s cubic-bezier(0.075, 0.82, 0.165, 1);

    .item-title {
      white-space: nowrap; /* Prevent the text from wrapping to multiple lines */
      overflow: hidden; /* Hide any overflowing text */
      text-overflow: ellipsis; /* Show ellipsis if the text overflows the container */
    }
    &.current {
      border: 1px solid var(--v-nav-base);
    }
  }
}

.list-enter {
  opacity: 0 !important;
  transform: scaleY(0.01) !important;
}

.list-enter-active {
  transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
  opacity: 1;
}

.list-leave {
  opacity: 1;
  transform: scaleY(1);
}

.list-leave-active {
  position: absolute;
  transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
  opacity: 0;
  transform: scaleY(0.01);
}

.list-move {
  transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
  background: var(--v-nav-lighten4);
}

.list-group-item {
  cursor: move;
}

.list-group-item i {
  cursor: pointer;
}
</style>
