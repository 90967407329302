import { getDownloadURL, ref, uploadBytes } from "firebase/storage";

export default class StorageHelper {
  constructor(storage) {
    this.storage = storage;
  }

  basePath = () => {
    return this.user.uid + "/";
  };
  compPath = () => {
    return this.company.id + "/";
  };

  getURL(path) {
    return getDownloadURL(ref(this.storage, path));
  }

  removeExtension(filename) {
    return filename.substring(0, filename.lastIndexOf(".")) || filename;
  }

  uploadFile(path, file, onUploaded, onError) {
    const mref = ref(this.storage, this.basePath() + path);
    uploadBytes(mref, file)
      .then((snapshot) => {
        onUploaded(snapshot);
      })
      .catch((e) => {
        onError(e);
      });
  }

  uploadFileToCompany(path, file, onUploaded, onError) {
    const mref = ref(this.storage, path);
    uploadBytes(mref, file)
      .then(async (upload) => {
        onUploaded({
          ...upload,
          path: await this.getURL(upload.metadata.fullPath),
          name: this.removeExtension(upload.metadata.fullPath.split("/").pop()),
          type: upload.metadata.fullPath.split(".").pop(),
        });
      })
      .catch((e) => {
        console.log("Upload error", e);
        onError(e);
      });
  }
}
