<template>
  <div class="vertical gapped" style="width: 100%; height: 100%">
    <div class="vertical gapped">
      <h4 v-if="!hideTitle">{{ hTitle ? hTitle : defaultHTitle }}</h4>
      <v-text-field
        v-if="!hideName"
        color="onBack"
        class="m-bg-white"
        name="title"
        :label="$t('location.short')"
        id="title"
        outlined
        rounded
        v-model="title"
        :error="!title"
        hide-details
      >
        <template #prepend-inner>
          <v-menu offset-y>
            <template #activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on"> mdi-map-marker </v-icon>
            </template>
            <v-list>
              <v-list-item
                v-for="item in $store.state.locationTitles"
                :key="item"
                link
                @click="title = item"
              >
                <v-list-item-title>{{ item }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-text-field>

      <div class="horizontal gapped half">
        <v-text-field
          color="onBack"
          :label="$t('location.street')"
          outlined
          rounded
          dense
          v-model="street"
          :error="!street"
          style="width: 75%"
          hide-details
        />
        <v-text-field
          color="onBack"
          :label="$t('location.nr')"
          outlined
          rounded
          dense
          v-model="nr"
          style="width: 25%"
          hide-details
        />
      </div>

      <div class="horizontal gapped half">
        <v-text-field
          color="onBack"
          :label="$t('location.zip')"
          outlined
          rounded
          dense
          v-model="zip"
          style="width: 25%"
          hide-details
        />
        <v-text-field
          color="onBack"
          :label="$t('location.city')"
          outlined
          rounded
          dense
          v-model="city"
          style="width: 75%"
          hide-details
        />
      </div>
    </div>
  </div>
</template>
<script>
import MiLocation from "../location";

export default {
  props: {
    location: {
      type: MiLocation,
      default: new MiLocation(),
    },
    hideName: Boolean,
    hTitle: String,
    hideTitle: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    defaultHTitle() {
      return this.$t("location.title");
    },
    title: {
      get() {
        return this.location.title;
      },
      set(value) {
        this.$emit("changed", { key: "title", value: value });
      },
    },
    street: {
      get() {
        return this.location.street;
      },
      set(value) {
        this.$emit("changed", { key: "street", value: value });
      },
    },
    nr: {
      get() {
        return this.location.nr;
      },
      set(value) {
        this.$emit("changed", { key: "nr", value: value });
      },
    },
    zip: {
      get() {
        return this.location.zip;
      },
      set(value) {
        this.$emit("changed", { key: "zip", value: value });
      },
    },
    city: {
      get() {
        return this.location.city;
      },
      set(value) {
        this.$emit("changed", { key: "city", value: value });
      },
    },
  },
};
</script>
<style lang="scss" scoped></style>
