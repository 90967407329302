import { where } from "firebase/firestore";
import { cloneDeep } from "lodash";
import firebase from "../../db/firebase/MFirebase";
import Struct from "./datas/todo.json";
import StructGroup from "./datas/todo.json";
import { todoTypes } from "../Types";

export class Todo {
  constructor(todo, group) {
    Object.assign(this, cloneDeep(Struct));
    if (todo) Object.assign(this, todo);
    if (group) this.group = group;
  }

  demo(gid, pid) {
    Object.assign(this, {
      title: "Test",
      subtitle: "test",
      desc: "Lorem ipsum",
      subs: [],
      users: [],
    });
    if (gid) this.gID = gid;
    if (pid) this.pID = pid;
    return this;
  }

  // Gets

  // Sets
  addUser(user) {
    this.users.push(user.toSmall());
  }

  toggleFinished() {
    this.setFinished(!this.finished);
  }

  setFinished(value) {
    this.finished = value;
    if (this.group) this.group.getProgress();
    this.save();
  }

  // Add, Save & Delete
  async delete() {
    if (this.group) this.group.getProgress();
    await firebase.deleteItem("todo", this.id);
  }

  deleteFields(item) {
    delete item.group;
  }

  // DB things
  toSaveable() {
    let item = { ...cloneDeep(this) };
    this.deleteFields(item);
    return item;
  }

  async save() {
    let item = { ...cloneDeep(this) };

    this.deleteFields(item);
    if (!item.id) {
      await firebase.addItem(todoTypes.todo.db, item, (it) => {
        this.id = it.id;
        item.id = it.id;
      });
    } else {
      await firebase.setItem(todoTypes.todo.db, item);
      if (this.group) this.group.getProgress();
    }
    return item;
  }
}

export class TodoGroup {
  todos = [];
  counts = {};
  loaded = false;
  listener = undefined;

  progress = {
    count: 0,
    finished: 0,
    percent: 0,
    loaded: false,
  };

  constructor(group, todos) {
    Object.assign(this, cloneDeep(StructGroup));
    if (group) Object.assign(this, group);
    if (todos) this.setTodos(todos);
    this.getProgress();
  }

  listenToEntries(onChanged) {
    if (this.listener == undefined) {
      this.listener = firebase.listenTo(
        todoTypes.todo.db,
        async (todos) => {
          todos = todos.map((it) => new Todo(it, this));
          this.setTodos(todos);
          if (onChanged) onChanged(todos, this.progress);
        },
        [where("gID", "==", this.id)],
        (err) => (this.err = err)
      );
    }
  }

  // Gets
  open() {
    return this.todos
      .filter((it) => !it.finished)
      .sort((a, b) => {
        if (!b.date) return -1;
        return a.date - b.date;
      });
  }

  finished() {
    return this.todos
      .filter((it) => it.finished)
      .sort((a, b) => {
        if (!b.date) return -1;
        return a.date - b.date;
      });
  }

  // Sets
  setTodos(todos) {
    this.todos = todos.map((it) => new Todo(it));
    this.loaded = true;
  }

  async getProgress() {
    let count = 0;
    let mopen = 0;
    let finished = 0;

    if (!this.listener) {
      mopen = await firebase.getCountsFor("todos", [
        where("gID", "==", this.id),
        where("finished", "==", false),
      ]);
      finished = await firebase.getCountsFor("todos", [
        where("gID", "==", this.id),
        where("finished", "==", true),
      ]);
      count = mopen + finished;
    } else {
      this.todos.forEach((it) => {
        count += 1;
        if (it.finished) finished += 1;
        else mopen += 1;
      });
    }

    let progress = {
      count: count,
      finished: finished,
      percent: count > 0 ? (finished / count).toFixed(2) * 100 : 0,
      loaded: true,
    };
    this.progress = progress;
    return progress;
  }

  deleteFields(item) {
    delete item.listener;
    delete item.loaded;
    delete item.todos;
    for (const key in item) {
      if (item[key] instanceof Function) {
        delete item[key];
      }
    }
  }

  async save() {
    let item = { ...cloneDeep(this) };
    this.deleteFields(item);
    if (!this.id) {
      await firebase.addItem(todoTypes.group.db, item, (it) => {
        this.id = it.id;
        item.id = it.id;
      });
    } else {
      await firebase.setItem(todoTypes.group.db, item);
    }
    return item;
  }

  async update(item) {
    this.deleteFields();
    await firebase.updateItem(todoTypes.group.db, item);
  }
}
