var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app-bar',{staticClass:"top-bar",staticStyle:{"z-index":"6"},attrs:{"app":"","value":!_vm.getHideTop,"flat":"","dark":"","elevation":"0","color":"transparent"}},[_c('div',{staticClass:"mi-connected",staticStyle:{"background-color":"#fefefe20"},style:(_vm.getHideLeft ? 'margin-left: 3rem' : '')},[_c('hover-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.getBreads.length > 1),expression:"getBreads.length > 1"}],attrs:{"icon":"mdi-chevron-left","title":"Zurück","color":"nav","openDelay":1000},on:{"click":function($event){return _vm.$router.go(-1)}}}),_vm._l((_vm.getBreads),function(bread,i){return _c('hover-button',{key:bread.text,attrs:{"dark":"","openDelay":1000,"icon":bread.icon,"title":bread.text,"expanded":i == _vm.getBreads.length - 1,"color":"transparent"},on:{"click":function($event){return _vm.$router.push({
          name: bread.to.name,
        })}}})})],2),_c('v-spacer'),(false)?_c('div',{staticClass:"mi-connected horizontal"},[(_vm.isSearchRoute)?_c('div',{staticClass:"horizontal"},[_c('v-expand-x-transition',[(!_vm.getType)?_c('div',{staticClass:"horizontal"},_vm._l((_vm.types),function(mtype){return _c('hover-button',{key:mtype.key,attrs:{"id":mtype.key,"icon":mtype.icon,"title":mtype.title,"openDelay":1000,"color":"transparent"},on:{"click":function($event){return _vm.setType(mtype)}}})}),1):_vm._e()]),_c('v-expand-x-transition',[(_vm.getType)?_c('div',{staticClass:"horizontal"},_vm._l((_vm.getFilterKeys),function(mFilterKey){return _c('con-button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.filterKey || _vm.filterKey == mFilterKey),expression:"!filterKey || filterKey == mFilterKey"}],key:mFilterKey,attrs:{"id":mFilterKey,"title":mFilterKey,"openDelay":1000,"color":_vm.filterKey == mFilterKey ? 'nav' : 'transparent'},on:{"click":function($event){_vm.filterKey == mFilterKey
                ? (_vm.filterKey = '')
                : (_vm.filterKey = mFilterKey)}}})}),1):_vm._e()]),_c('v-text-field',{attrs:{"solo":"","flat":"","placeholder":"Suche","backgroundColor":_vm.filterKey && !_vm.filterText ? 'actions' : 'transparent',"hide-details":""},model:{value:(_vm.filterText),callback:function ($$v) {_vm.filterText=$$v},expression:"filterText"}})],1):_vm._e(),_c('con-button',{attrs:{"color":"transparent","icon":'mdi-magnify',"disabled":_vm.isSearchRoute && (!_vm.filterKey || !_vm.filterText)},on:{"click":function($event){!_vm.isSearchRoute
          ? _vm.$router.push({ name: 'SearchPage' })
          : _vm.filterKey && _vm.filterText
          ? _vm.addAndClearFilter()
          : ''}}})],1):_vm._e(),(!_vm.hideAppBarActions)?_c('div',{staticClass:"horizontal gapped",style:(_vm.getHideRight ? 'margin-right: 3rem' : '')},[_c('v-badge',{attrs:{"color":"nav","content":_vm.$store.state.feedbacks.length,"value":_vm.$store.state.feedbacks.length,"top":"","overlap":""}},[(_vm.$store.state.userData?.isDev)?_c('v-btn',{attrs:{"large":"","icon":"","color":"white"},on:{"click":function($event){_vm.$store.state.dialogs.dev.show = true}}},[_c('v-icon',[_vm._v("mdi-dev-to")])],1):_vm._e()],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }