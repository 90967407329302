/** @format */

import {
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";

const provider = new GoogleAuthProvider();

export default class AuthHelper {
  constructor(auth) {
    this.auth = auth;
  }

  // Listen w/ Userdata from Store
  listenToUser(onChanged, onError) {
    return this.auth.onAuthStateChanged(onChanged, onError);
  }

  // Login
  loginWithGoogle(onFound, onError) {
    return signInWithPopup(this.auth, provider)
      .then((result) => {
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        onFound(result.user, token);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        const email = error.customData.email;
        const credential = GoogleAuthProvider.credentialFromError(error);
        console.log("Error :( ", errorCode, errorMessage, email, credential);
        onError({
          code: errorCode,
          message: errorMessage,
          email: email,
          credential: credential,
        });
      });
  }

  loginWithEmail(email, pw, onFound, onError) {
    return signInWithEmailAndPassword(this.auth, email, pw)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        onFound(user);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        onError({
          code: errorCode,
          message: errorMessage,
        });
      });
  }

  // Create
  createWithEmail(email, pw, onFound, onError) {
    return createUserWithEmailAndPassword(this.auth, email, pw)
      .then((userCredential) => {
        if (onFound) onFound(userCredential.user);
      })
      .catch((error) => {
        if (onError) onError({ code: error.code, message: error.message });
      });
  }
}
