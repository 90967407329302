// aiModule.js
import { buildApiConfigurationMessage } from "../../ai/promptGenerator";
import { createMessage, sendMessages } from "../../ai/chatGPT";

const systemMessage = createMessage("system", buildApiConfigurationMessage());
export const infos = {
  type: "",
  search: {
    searchKey: "",
    message: "",
  },
  ffunction: "",
};

export default {
  namespaced: true,
  name: "ai",
  state: {
    currentConversation: {
      title: "",
      messages: [],
      infos: {
        ...infos,
      },
    },
    enteredText: "",
  },

  mutations: {
    ADD_MESSAGE(state, message) {
      state.currentConversation.messages.push({
        ...message,
      });
    },
    SET_INFOS(state, info) {
      state.currentConversation.infos = info;
    },
    SET_CURRENT_CONVERSATION_TITLE(state, title) {
      state.currentConversation.title = title;
    },
    RESET_CONVERSATION(state) {
      state.currentConversation = {
        title: "",
        messages: [],
        infos: {
          type: "",
          search: {
            searchKey: "",
            message: "",
          },
        },
      };
    },
    UPDATE_ENTERED_TEXT(state, text) {
      state.enteredText = text;
    },
  },

  actions: {
    async sendMessage({ commit, state }, content) {
      const message = createMessage("user", content);
      commit("ADD_MESSAGE", message);
      const response = await sendMessages([
        systemMessage,
        ...state.currentConversation.messages,
      ]);
      commit("ADD_MESSAGE", response);
    },

    async sendSystemMessage({ commit, state }, content) {
      const message = createMessage("system", content);
      commit("ADD_MESSAGE", message);
      const response = await sendMessages([
        systemMessage,
        ...state.currentConversation.messages,
      ]);
      commit("ADD_MESSAGE", response);
    },

    addItemToConversation({ commit }, item) {
      commit("ADD_MESSAGE", createMessage("item", item));
    },

    setInfos({ commit }, info) {
      commit("SET_INFOS", info);
    },

    setAction({ commit }, action) {
      commit("SET_ACTION", action);
    },

    resetConversation({ commit }) {
      commit("RESET_CONVERSATION");
    },

    updateEnteredText({ commit }, text) {
      commit("UPDATE_ENTERED_TEXT", text);
    },

    setCurrentConversationTitle({ commit }, title) {
      commit("SET_CURRENT_CONVERSATION_TITLE", title);
    },
  },

  getters: {
    currentConversation: (state) => state.currentConversation,
    enteredText: (state) => state.enteredText,
    conversationMessages: (state) =>
      [...state.currentConversation.messages]
        .filter((it) => it.role != "system")
        .sort((a, b) => b.timestamp - a.timestamp),
    getUserForMessage: (state) => (messageId) =>
      state.currentConversation.messages.find(
        (message) => message.id === messageId
      ).userId,
    getRoleForMessage: (state) => (messageId) =>
      state.currentConversation.messages.find(
        (message) => message.id === messageId
      ).role,
  },
};
