import Struct from "./datas/doc.json";
import DocTypes from "./datas/doctypes.json";

import { Type } from "../Types";
import { cloneDeep } from "lodash";
import firebase from "../../db/firebase/MFirebase";
import { miLog } from "../../main";
import DocPos from "./docPos";

export const docTypes = DocTypes;
export const docTypesWithItems = [];
export const docTypesWithoutItems = [];
export const docTypeKeysWithItems = [];
export const docTypeKeysWithoutItems = [];

for (const docTypeKey of Object.keys(DocTypes)) {
  const docType = DocTypes[docTypeKey];

  if (docType.withItems) {
    docTypesWithItems.push(docType);
    docTypeKeysWithItems.push(docType.key);
  } else {
    docTypesWithoutItems.push(docType);
    docTypeKeysWithoutItems.push(docType.key);
  }
}

export default class Doc {
  counts = {
    basedatas: 0,
    items: 0,
  };

  constructor(doc, subType) {
    miLog("Creating Doc...", doc, subType);
    if (Struct) {
      Object.assign(this, Struct);
    }
    if (doc) {
      if (doc.positions)
        doc.positions = doc.positions.map((it) => new DocPos(it));
      Object.assign(this, doc);
      this.type = new Type("docs");

      let docType = doc.docType ? doc.docType : subType;
      if (docType) {
        this.docType = docType;
      } else {
        this.docType = subType;
      }

      if (!this.title) {
        this.title = this.docType + "#" + doc.id;
      }
    }

    miLog("Created Doc...", this);
  }

  typeName() {
    return DocTypes[this.docType].titles.single;
  }

  setProject(project) {
    this.pID = project.id;
    miLog("Setting project", project.location);
    if (project.location) {
      Object.assign(this.deliver, { location: project.location });
      Object.assign(this.pay, { location: project.location });
    }
    if (project.contact) this.contact = project.contact;
    if (project.user) this.user = project.user;
  }

  toSmall() {
    return {
      id: this.id,
      subType: this.type,
      title: this.title,
    };
  }

  // Add, Save etc
  toSaveable() {
    const itemToSave = { ...cloneDeep(this) };
    delete itemToSave.type;
    delete itemToSave.subToSave;

    if (itemToSave.contact) {
      itemToSave.contact = this.subToSave(itemToSave.contact);
    }

    if (itemToSave.pay?.location) {
      itemToSave.pay.location = this.subToSave(itemToSave.pay.location);
    }

    if (itemToSave.deliver?.location) {
      itemToSave.deliver.location = this.subToSave(itemToSave.deliver.location);
    }

    if (itemToSave.positions?.length) {
      itemToSave.positions = itemToSave.positions.map((it) =>
        this.subToSave(it)
      );
    }

    miLog("Saveable item", itemToSave);
    return itemToSave;
  }

  subToSave = (item) => {
    try {
      return item.toSaveable();
    } catch {
      return item;
    }
  };

  async save() {
    let item = this.toSaveable();
    if (this.id) await firebase.setItem("doc", item);
    else {
      item = await firebase.addItem("doc", item);
      this.id = item.id;
      this.created = item.created;
      this.updated = item.updated;
    }
    return item;
  }
}
