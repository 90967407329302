import { where } from "firebase/firestore";
import { cloneDeep, groupBy } from "lodash";
import firebase from "../../db/firebase/MFirebase";
import { Todo } from "../todo/Todos";
import { miLog } from "../../main";
import Contact from "../contact/contact";
import Customer from "../customer/customer";
import Doc from "../doc/doc";
import Location from "../location/location";
import { Type } from "../Types";
import Struct from "./datas/project.json";
import MiForm from "../form/form";

export default class Project {
  listeners = {};

  constructor(project) {
    if (Struct) {
      Object.assign(this, Struct);
    }
    if (project) {
      Object.assign(this, project);
      if (project instanceof Project) return;
    }
    miLog("Creating new Project...");
    if (!this.forms) this.forms = [];
    this.forms = this.forms.map((it) => new MiForm(it));

    this.customer = new Customer(this.customer);
    this.location = new Location(this.location);
    this.contact = new Contact(this.contact);
    this.goals = this.goals.map((it) => it);

    this.type = new Type("project");

    miLog("Created Project", project);
  }

  getProgress() {}

  getAllData() {
    miLog("Getting Data for Project");
    this.listenDocs();
    this.listenTodos();
  }

  listenDocs() {
    this.listeners["doc"] = firebase.listenTo(
      "doc",
      (docs) => {
        docs = docs.map((it) => new Doc(it));
        let gDocs = groupBy(docs, (doc) => doc.docType);
        for (let key in gDocs) {
          // this.previews.docs[key] = gDocs[key].map((it) => it.toSmall());
          this.docs[key] = gDocs[key];
        }
        miLog("Got Docs", gDocs);
      },
      [where("pID", "==", this.id)]
    );
  }

  listenTodos() {
    this.listeners["todos"] = firebase.listenTo(
      "todos",
      (todos) => {
        todos = todos.map((it) => new Todo(it));
        let gTodos = groupBy(todos, (todo) => todo.gID);
        miLog("Got Todos", gTodos);
      },
      [where("pID", "==", this.id)]
    );
  }

  // DB things
  checkAllGood() {
    return !!this.title;
  }

  toSaveable() {
    let item = { ...cloneDeep(this) };
    delete item.type;
    delete item.docs;
    delete item.todos;
    delete item.previews;
    delete item.listeners;
    item.customer = this.subToSave(item.customer);
    item.location = this.subToSave(item.location);
    item.contact = this.subToSave(item.contact);
    item.forms = item.forms.map((it) => this.subToSave(it));
    if (item.users)
      item.users = item.users.map((it) => {
        return this.subToSave(it);
      });
    return item;
  }

  subToSave(item) {
    try {
      if (item.toSaveable) return item.toSaveable();
      else return item;
    } catch {
      return item;
    }
  }

  async add(onAdd) {
    let item = { ...this.toSaveable() };
    item = await firebase.addItem(this.type.key, item, onAdd);
    this.id = item.id;
    this.created = item.created;
    return item;
  }

  async save(onAdd) {
    let item = { ...this.toSaveable() };
    if (!item.id) {
      return this.add(onAdd);
    }
    miLog("Saving Project:", item);
    return await firebase.setItem(this.type.key, item);
  }

  delete() {}
}
