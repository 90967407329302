<template>
  <base-dialog
    dialKey="form"
    scrollable
    :overlay="true"
    :title="form.title"
    :icon="form.icon"
  >
    <template #text>
      <div class="vertical gapped" style="padding: 1rem 0">
        <v-text-field
          v-for="entry in form.entries"
          :key="entry.title"
          :name="entry.title"
          :label="entry.title"
          :id="entry.title"
          v-model="entry.value"
          color="onBack"
          hide-details
          rounded
          outlined
          background-color="#fefefe33"
        ></v-text-field>
      </div>
    </template>
  </base-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import BaseDialog from "../../../views/dialogs/BaseDialog.vue";
export default {
  components: { BaseDialog },
  name: "FormDialog",

  computed: {
    ...mapGetters("dialog", ["getDialogData"]),

    form() {
      return this.getDialogData("form");
    },
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped></style>
