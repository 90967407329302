import icons from "../plugins/icons";

export default {
  dash: {
    text: "Dashboard",
    disabled: false,
    to: {
      path: "/c/:compId",
      name: "Company",
    },
    icon: "mdi-view-dashboard",
  },
  settings: {
    text: "Einstellungen",
    disabled: false,
    to: {
      path: "/settings",
      name: "Settings",
    },
    icon: "mdi-cog",
  },

  // Project
  project: {
    text: "Projekt",
    disabled: true,
    icon: icons.project,
  },
  projects: {
    text: "Projekte",
    disabled: false,
    to: {
      path: "/list?type=project",
      name: "ProjectPage",
    },
    icon: icons.project,
  },

  //   Customer
  customer: {
    text: "Kunde",
    disabled: true,
    icon: icons.customer,
  },
  customers: {
    text: "Kunden",
    disabled: false,
    to: {
      path: "/list?type=customer",
      name: "SearchCustomerPage",
    },
    icon: icons.customer,
  },

  supplier: {
    text: "Lieferanten",
    disabled: false,
    to: {
      path: "/list?type=supplier",
      name: "SupplierPage",
    },
    icon: icons.supplier,
  },
  material: {
    text: "Artikel",
    disabled: false,
    to: {
      path: "/list?type=basedata",
      name: "BasedataPage",
    },
  },
  users: {
    text: "Users",
    disabled: false,
    to: {
      path: "/list?type=worker",
      name: "UserPage",
    },
  },
  docs: {
    icon: "$docs",
    text: "Belege",
    disabled: false,
    to: {
      path: "/list?type=docs",
      name: "DocPage",
    },
  },
  todos: {
    text: "Todos",
    disabled: true,
  },
  print: {
    text: "Vorschau",
    disabled: true,
    icon: "mdi-printer",
  },

  create: (icon, text, disabled, to) => {
    return {
      icon: icon,
      text: text,
      disabled: disabled,
      to: to,
    };
  },
};
