<template>
  <div class="vertical m-bg-white rounded">
    <div class="vertical">
      <v-btn
        text
        class="vertical miuser"
        @click="showDialog({ key: 'user', data: getUser })"
      >
        <div class="vertical gapped half">
          <v-icon>$user</v-icon>
          <span>{{ getUser.title ? getUser.title : "Du" }}</span>
        </div>
      </v-btn>

      <v-btn
        text
        class="vertical user"
        v-for="worker in users"
        :key="worker.id"
        @click="showDialog({ key: 'user', data: worker })"
      >
        <div class="vertical gapped half">
          <v-icon>$worker</v-icon>
          <span>{{ worker.title }}</span>
        </div>
      </v-btn>
    </div>

    <v-spacer></v-spacer>

    <v-divider></v-divider>
    <v-btn
      text
      class="vertical user"
      @click="showDialog({ key: Dialogs.request })"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { Dialogs } from "../../../../store/modules/dialogModule";
export default {
  name: "UserBar",

  data() {
    return {
      Dialogs: Dialogs,
    };
  },

  computed: {
    ...mapGetters("miuser", ["getUser"]),
    ...mapGetters("context", ["users", "selectUserview"]),
  },

  methods: {
    ...mapActions("dialog", ["showDialog"]),
  },
};
</script>

<style lang="scss" scoped>
.rounded {
  border-radius: 2rem !important;
  overflow: hidden;
}

.miuser {
  width: 100%;
  padding: 2.5rem 0 2rem 0 !important;
  background-color: var(--v-accent-darken1);
}
.user {
  width: 100%;
  padding: 2.5rem 0 !important;
}
</style>
