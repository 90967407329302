<template>
  <v-navigation-drawer
    class="sidebar elevation-0"
    color="transparent"
    width="80px"
    app
    right
    :value="showSide"
    :permanent="showSide"
    floating
    style="padding: 1rem 0.5rem"
  >
    <div
      class="vertical gapped centered-h"
      style="height: 100%; padding-top: 3rem"
    >
      <v-btn color="white" icon x-large @click="$router.push('/login')">
        <v-img
          v-if="getUser && getUser.photoURL"
          width="60px"
          height="60px"
          :lazy-src="getUser.photoURL"
          rel="noreferrer"
          contain
          style="border: 1px solid white; border-radius: 50%"
        ></v-img>
        <v-icon v-else large>mdi-login-variant</v-icon>
      </v-btn>

      <user-bar style="height: 100%" v-if="false" />

      <v-spacer />

      <v-btn
        color="white"
        icon
        x-large
        @click="$router.push({ name: 'SettingsPage' })"
      >
        <v-icon> mdi-cog </v-icon>
      </v-btn>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import UserBar from "./UserBar.vue";
export default {
  name: "RightAppBar",

  components: {
    UserBar,
  },

  mounted() {
    if (this.smallState) {
      this.setHideRight(true);
    }
  },

  computed: {
    ...mapGetters("miuser", ["getUser"]),
    ...mapGetters(["smallState"]),
    ...mapGetters("appbar", ["getHideRight"]),

    showSide: {
      get() {
        return !this.getHideRight;
      },
      set() {
        // this.setHideSide(!value);
      },
    },
  },

  methods: {
    ...mapActions("appbar", ["setHideRight"]),
  },
};
</script>

<style lang="scss" scoped></style>
