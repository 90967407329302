import Doc from "../../types/doc/doc";
import DocTypes from "../../types/doc/datas/doctypes.json";

import firebase from "../../db/firebase/MFirebase";
import Project from "../../types/project/project";
import { miLog } from "../../main";
import { isEqual } from "lodash";

const initState = {
  // IDs
  dID: null,
  pID: null,

  // Items
  doc: null,
  project: null,
};

export default {
  namespaced: true,
  name: "doc",

  state: {
    ...initState,
  },

  mutations: {
    // Project
    SET_PROJECT(state, project) {
      miLog("docModule", "SET_PROJECT");
      if (!(project instanceof Project)) project = new Project(project);
      state.project = project;
      state.pID = project.id;
    },

    // Doc
    SET_DOC(state, doc) {
      miLog("docModule", "SET_DOC");
      if (!(doc instanceof Doc)) doc = new Doc(doc);
      state.dID = doc.id;
      state.doc = doc;
    },

    SET_DOC_TITLE(state, title) {
      state.doc.title = title;
    },

    SET_DOC_CUSTOMER(state, customer) {
      state.doc.customer = customer;
    },

    SET_DOC_CONTACT(state, contact) {
      state.doc.contact = contact;
    },

    SET_DOC_DELIVERY_CONTACT(state, contact) {
      state.doc.delivery.contact = contact;
    },

    SET_DOC_PAYMENT_CONTACT(state, contact) {
      state.doc.payment.contact = contact;
    },

    SET_DOC_LOCATION(state, location) {
      state.doc.location = location;
    },

    SET_DOC_NOTES(state, notes) {
      state.doc.notes = notes;
    },

    SET_DOC_LOGO(state, logo) {
      miLog("Setting doc logo", logo);
      state.doc.logo = logo;
    },

    // Positions
    SET_DOC_POSITIONS(state, positions) {
      state.doc.positions = positions;
    },

    ADD_DOC_POSITION(state, position) {
      state.doc.positions.push(position);
    },

    REMOVE_DOC_POSITION(state, positionId) {
      state.doc.positions = state.doc.positions.filter(
        (position) => position.id !== positionId
      );
    },
  },

  actions: {
    // Project
    async setProjectID({ state, dispatch }, pID) {
      if (state.project && state.project.id == pID) return;

      let project = await firebase.getItem("project", pID);
      dispatch("setProject", project);
    },

    setProject({ state, commit }, project) {
      if (isEqual(state.project, project)) return;

      commit("SET_PROJECT", project);
      if (!state.doc || !project) return;

      if (!state.doc.contact) commit("SET_DOC_CONTACT", project.contact);
      if (!state.doc.customer) commit("SET_DOC_CUSTOMER", project.customer);
      if (!state.doc.location) commit("SET_DOC_LOCATION", project.location);
    },

    // Doc
    async setDocID({ state, dispatch }, docID) {
      if (state.doc && state.doc.id == docID) return;

      let doc = await firebase.getItem("doc", docID);
      dispatch("setDoc", doc);
    },

    setDoc({ state, commit }, doc) {
      if (isEqual(state.doc, doc)) return;

      commit("SET_DOC", doc);

      if (!state.project || !doc) return;

      if (!state.doc.contact) commit("SET_DOC_CONTACT", state.project.contact);
      if (!state.doc.customer)
        commit("SET_DOC_CUSTOMER", state.project.customer);
      if (!state.doc.location)
        commit("SET_DOC_LOCATION", state.project.location);
    },

    setDocTitle({ commit }, title) {
      commit("SET_DOC_TITLE", title);
    },

    setDocCustomer({ commit }, customer) {
      commit("SET_DOC_CUSTOMER", customer);
    },

    setDocContact({ commit }, contact) {
      commit("SET_DOC_CONTACT", contact);
    },

    setDocLocation({ commit }, location) {
      commit("SET_DOC_LOCATION", location);
    },

    setDocNotes({ commit }, notes) {
      commit("SET_DOC_NOTES", notes);
    },

    setDocPositions({ commit }, positions) {
      commit("SET_DOC_POSITIONS", positions);
    },

    addDocPosition({ commit }, position) {
      commit("ADD_DOC_POSITION", position);
    },

    removeDocPosition({ commit }, position) {
      const positionId = typeof position === "object" ? position.id : position;
      commit("REMOVE_DOC_POSITION", positionId);
    },

    setDocLogo({ commit }, logo) {
      commit("SET_DOC_LOGO", logo);
    },

    // Helpers
  },

  getters: {
    // Project
    project: (state) => state.project,
    projectId: (state) => state.pID,

    // Doc
    doc: (state) => state.doc,
    docId: (state) => state.dID,
    docType: (state) => (state.doc ? DocTypes[state.doc.docType] : null),
    docTypeTitles: (state) =>
      state.doc ? DocTypes[state.doc.docType].titles : null,

    docTitle: (state) => state.doc?.title,
    docCustomer: (state) => state.doc?.customer,
    salutation: (state) => {
      if (!state.docCustomer) return "";
      if (state.docCustomer.sex === "m") {
        return "Herr";
      } else {
        return "Frau";
      }
    },
    docContact: (state) => state.doc?.contact,
    docDeliveryContact: (state) => state.doc?.delivery.contact,
    docPaymentContact: (state) => state.doc?.payment.contact,
    docLocation: (state) => state.doc?.location,
    docNotes: (state) => state.doc?.notes,
    docLogo: (state) => state.doc.logo,

    getAllGood: (state) => state.doc.contact && state.doc.location,

    // Positions
    docPositions: (state) => state.doc?.positions || [],

    // Amounts
    docVK: (state) => {
      const positions = state.doc?.positions || [];
      return positions.reduce((total, position) => total + position.vk, 0);
    },

    docTaxes: (state) => {
      const positions = state.doc?.positions || [];
      const taxes = {};

      positions.forEach((position) => {
        const { mwst, vk } = position;
        if (mwst && vk) {
          if (!taxes[mwst]) {
            taxes[mwst] = 0;
          }
          taxes[mwst] += Number(vk) * (Number(mwst) / 100);
        }
      });

      return taxes;
    },

    docGes: (state) => {
      const positions = state.doc?.positions || [];
      let ges = 0;

      positions.forEach((position) => {
        const { vk, mwst } = position;
        ges += vk + vk * mwst;
      });

      return ges;
    },
  },
};
